import React, { useEffect } from "react";

import {default as ToggleButtonUI} from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Icon from "../Icon/Icon";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: 0,
        gap: 5,
        color: `var(--color-white-500)`,
        backgroundColor: `var(--color-paper-400)`,
        border: `1px solid var(--color-gray-700)`,
        height: 38,
        transition: "all 0.5s ease",
        letterSpacing: 0,
        textTransform: "none",
        '&:hover': {
            color: 'white',
            backgroundColor: `var(--color-primary-400)`,
            borderColor: `var(--color-primary-500)`,
        },
        '&.Mui-selected': {
            cursor: "default",
            color: 'white',
            backgroundColor: `var(--color-primary-500)`,
            borderColor: `var(--color-primary-500)`,
        },
        '&.Mui-selected:hover': {
            backgroundColor: `var(--color-primary-500)`,
        },
        '&.Mui-disabled': {opacity: 0.5, cursor: "not-allowed"},
        '&:first-of-type': {
            borderRight: `1px solid var(--color-gray-700)`,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
        '&:last-of-type': {
            borderLeft: `1px solid var(--color-gray-700)`,
            borderTopRightRadius: 8,
            borderBottomRightRadius: 8,
        },
    },
}));

const ToggleButton: React.FC<{
    state?: any;
    setState?: any;
    items: {
        label: string;
        value: string | number;
        icon?: string;
        onClick?: any;
        isSelected: boolean;
    }[]
}> = ({
  items = [],
  state, setState
}) => {
    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment === null) return
        setState(newAlignment);
    }
    useEffect(() => {
        const itemSelected = items.filter(i => i.isSelected)[0]
        if (!itemSelected) return
        setState(itemSelected.value)
    }, [])
    return (
        <StyledToggleButtonGroup
            value={state}
            onChange={handleAlignment}
            exclusive
            // aria-label="text alignment"
        >
            {items.length > 0 && items.map((i, index) => {
                return <ToggleButtonUI 
                    key={'toggle-button'+index} 
                    value={i.value}
                    aria-label={i.label}
                >
                    {i.icon && <Icon name={String(i.icon)} size={18} />}
                    <div>{i.label}</div>
                </ToggleButtonUI>
            })}
        </StyledToggleButtonGroup>
    );
};
export default ToggleButton;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import defaxios from "axios";
import { IEmailState } from "types/email/email.type";

const baseUrl = process.env.REACT_APP_BASE_URL;

const initialState: IEmailState = {
  isLoading: false,
  data: {} as IEmailState['data'],
  successMessage: "",
  isSuccess: false,
  errorMessage: "",
  sendAgain: {
    isLoading: false,
    successMessage: "",
    isSuccess: false,
    errorMessage: "",
  },
};
export const verifyAccount = createAsyncThunk("auth/verifyAccount", async (data: string, thunkAPI) => {
  try {
    const response = await defaxios.get(`${baseUrl}auth/verify_account_email/${data}`);
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const resendVerificationEmail = createAsyncThunk("auth/resendVerficiationEmail", async (token: string, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}auth/resend_verification_email`);
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    resetEmailState: (state) => {
      state.isLoading = false;
      state.successMessage = "";
      state.isSuccess = false;
      state.errorMessage = "";
      state.sendAgain.isLoading = false;
      state.sendAgain.successMessage = "";
      state.sendAgain.isSuccess = false;
      state.sendAgain.errorMessage = "";
    },
    resetSendAgain: (state) => {
      state.sendAgain.isLoading = false;
      state.sendAgain.successMessage = "";
      state.sendAgain.isSuccess = false;
      state.sendAgain.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyAccount.pending, (state) => {state.isLoading = true;});
    builder.addCase(verifyAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      state.successMessage = "";
      state.isSuccess = true;
    });
    builder.addCase(verifyAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
      state.data = action.payload.data;
      state.isSuccess = true;
    });
    builder.addCase(resendVerificationEmail.pending, (state) => {state.sendAgain.isLoading = true;});
    builder.addCase(resendVerificationEmail.rejected, (state, action: any) => {
      state.sendAgain.isLoading = false;
      state.sendAgain.errorMessage = (action.payload && action.payload?.message) || 'Something went wrong. Please try again later.';
      state.sendAgain.successMessage = "";
      state.sendAgain.isSuccess = true;
    });
    builder.addCase(resendVerificationEmail.fulfilled, (state, action) => {
      state.sendAgain.isLoading = false;
      state.sendAgain.errorMessage = "";
      state.sendAgain.successMessage = action.payload.message;
      state.sendAgain.isSuccess = true;
    });
  },
});

export const { resetEmailState, resetSendAgain } = emailSlice.actions;

import React, { useState } from "react";
import styles from "./style.module.scss";
import { deleteFirstOfArray } from "utils/workWithData";
import {Tooltip, Icon} from "components";
import { getAuthenticityString } from "utils/enums/authenticity";
import EntryFormatter from "utils/EntryFormatter";

const DropDownIItems: React.FC<any> = ({ data, type }) => {
  const [open, setOpen] = useState<boolean>(false);
  const authenticityStr = getAuthenticityString(data[0]?.authenticity);
  return <>
    {EntryFormatter.exists(data) && typeof data === "object" 
    ? (
      <div className="flex">
        {/* <Tooltip content={LeadHelper.getConfidenceString(data?.confidence) || ""}> */}
        {/* TODO: fix it. Since confidence is not shared in the response, we should find a way to get it from the response.
        ${LeadHelper.getConfidenceString(data?.confidence)} */}
          <div className={`badge sm truncate green`}>
            {data[type]}
          </div>
        {/* </Tooltip> */}
      </div>
    )
    : Array.isArray(data) && data.length > 0
    ? (
      <div className={styles.container}>
        <div className={styles.container_box}>
          <Tooltip content={authenticityStr || ""}>
            <div
              className={`badge sm truncate ${authenticityStr && authenticityStr}`}
            >
              {data[0][type] || "-"}
            </div>
          </Tooltip>
          {data.length > 1 && (
            <div
              onClick={() => setOpen(!open)}
              className={styles.container_box_svg}
            >
              <Icon name={open ?"chevron-up":"chevron-down"} size={10} />
            </div>
          )}
        </div>
        {open && 
          <div className={styles.container_dropDownItems}>{
            deleteFirstOfArray(data).map((elm: any, i: number) => {
              const elemAuthenticity = getAuthenticityString(elm.authenticity);
              return (
                <div
                  key={i} className={`badge sm truncate ${elemAuthenticity}`}
                >
                  <Tooltip content={elemAuthenticity || ""}>
                    <span>
                      {elm[type] ? elm[type] : "-"}
                    </span>
                  </Tooltip>
                </div>
              );
            })
          }</div>}
      </div>
    ) 
    : <span> - </span>
  }
  </>
};

export default DropDownIItems;

import React from "react";
import styles from "./style.module.scss";
import Icon from "../Icon/Icon";
const Loading: React.FC<{
  height?: string;
  background?: string;
  width?: string;
  spinnerSize?: number;
  color?: string;
}> = ({ height, background, width, spinnerSize = 41, color = "var(--color-primary-500)" }) => {
  return (
    <div
      className={styles.spinner_container}
      style={{
        height: height || "100vh",
        background: background || "transparent",
        width: width || "100%"
      }}
    >
      <div className={styles.loading_spinner}>
        <Icon name="loader" size={spinnerSize} color={color} />
      </div>
    </div>
  );
};

export default Loading;

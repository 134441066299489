import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import Icon from "components/atoms/Icon/Icon";
import InputSelect from "components/atoms/InputSelect/InputSelect";
import { GROUPED_OPTIONS_JOBTITLE, OPTIONS_INDUSTRY, OPTIONS_JOBTITLE, OPTIONS_LOCATION } from "../SearchMain/SearchMainHelper";
import Button from "components/atoms/Button/Button";
import InputField from "components/atoms/InputField/InputField";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setTriggerApplyFilters } from "store/slices/ui.slice";
import {useSearchParams} from "react-router-dom";
import {SetStateFunction} from "../../../utils/filterHandlerWithGeneralOptions";
import { resetClientAnalyticsData } from "store/slices/client.slice";

const AnalyticsFilters: FC<{
  isGroupedOptions?: boolean,
  filterIndustry: any[];
  setFilterJobTitle?: SetStateFunction;  filterJobTitle?: any[]; 
  setFilterIndustry: Dispatch<SetStateAction<any[]>>; filterLocationPerson: any[];
  setFilterLocationPerson: Dispatch<SetStateAction<any[]>>; filterSeniority?: any[];
  setFilterSeniority?: Dispatch<SetStateAction<any[]>>; filterDepartment?: any[];
  setFilterDepartment?: Dispatch<SetStateAction<any[]>>; filterCompanyHeadcount?: any[];
  setFilterCompanyHeadcount?: Dispatch<SetStateAction<any[]>>;
  isAppliedFilter: boolean; setIsAppliedFilter: (newVal: boolean) => void; 
  setPageCurrent: (newVal: number) => void;
  pageCurrent: number
}> = ({ isGroupedOptions = false,  filterIndustry,
  setFilterIndustry, filterLocationPerson, setFilterLocationPerson,
  filterSeniority, setFilterSeniority, filterDepartment, setFilterDepartment, filterCompanyHeadcount,
  setFilterCompanyHeadcount,
  isAppliedFilter, 
  setIsAppliedFilter, 
  setFilterJobTitle,
  filterJobTitle,
  setPageCurrent, pageCurrent,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch();
  const triggerApplyFilters = useAppSelector((state) => state.ui.triggerApplyFilters);
  const filterDeps = [
    filterJobTitle,
    filterIndustry,
    filterLocationPerson,
    filterSeniority,
    filterDepartment,
    filterCompanyHeadcount,
  ];
  const isDisabled = useMemo(() => {
    return filterDeps.every((dep: any) => {
      return !(dep?.length > 0)
    });
  }, filterDeps);

  const resetFilters = useCallback(() => {
    dispatch( resetClientAnalyticsData() )
    setFilterJobTitle?.([]);
    setFilterIndustry?.([]);
    setFilterLocationPerson([]);
    setFilterSeniority?.([]);
    setFilterDepartment?.([]);
    setFilterCompanyHeadcount?.([]);
    setPageCurrent(1);
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.delete("job_title");
      newParams.delete("seniority");
      newParams.delete("department");
      newParams.delete("industry");
      newParams.delete("person_location")
      newParams.delete("company_headcount");
      newParams.set("current_page", "1");
      return newParams;
    })
  }, filterDeps);
  const applyFilters = useCallback(() => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      const filters = [
        { key: "job_title", value: filterJobTitle },
        { key: "seniority", value: filterSeniority },
        { key: "department", value: filterDepartment },
        { key: "industry", value: filterIndustry },
        { key: "person_location", value: filterLocationPerson },
        { key: "company_headcount", value: filterCompanyHeadcount },
      ];

      filters.forEach(({ key, value }) => {
        value?.length
          ? newParams.set(key, typeof value === "string" ? value : JSON.stringify(value))
          : newParams.delete(key);
      });
      return newParams;
    })
    setIsAppliedFilter(true);
    setPageCurrent(1);
  }, filterDeps);

  useEffect(() => {
    if (isDisabled) {
      resetFilters()
      // fetchResults();
      setIsAppliedFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  useEffect(() => {
    // when the value is updated from within another component's scope
    if( triggerApplyFilters) {
      applyFilters();
      dispatch(setTriggerApplyFilters(false));
    }
  }, [triggerApplyFilters])
  return (
    <>
      <div className="flex flex-row justify-between gap-2 px-5 pt-4 pb-1 w-full">
        <Tooltip isFullWidth content={isDisabled ? "Kindly select atleast one filter" : ""}>
          <Button
            disabled={isDisabled}
            onClick={() => applyFilters()}
            iconName="filter"
            iconSize={17}
            size="sm" className="w-full"
            isProcessingTitle="Fetching..."
          >
            Apply Filters
          </Button>
        </Tooltip>
        <Button
          disabled={isDisabled} size="sm"
          onClick={() => resetFilters()}
          className="w-fit" iconName="trash" iconSize={14}
        >Clear</Button>
      </div>
      <ul id="menu-parent" className="SearchMainFilter">

        {filterJobTitle && 
          <FilterItem label="Title" icon="job" state={filterJobTitle} setState={setFilterJobTitle}
            isActive={filterJobTitle?.length > 0} options={isGroupedOptions ? GROUPED_OPTIONS_JOBTITLE : OPTIONS_JOBTITLE} placeholder="Enter job titles" 
          />}
        {/* {filterSeniority && <FilterItem label="Seniority" icon="seniority" state={filterSeniority} setState={setFilterSeniority}
          isActive={filterSeniority?.length > 0} options={OPTIONS_SENIORITY} placeholder="Enter seniorities" />
        } */}
        {/* {filterDepartment &&
            <FilterItem label="Department" icon="category" state={filterDepartment} setState={setFilterDepartment}
            isActive={filterDepartment?.length > 0} options={OPTIONS_DEPARTMENT} menuPlacement="top"
            placeholder="Enter departments" />
        } */}
        {filterIndustry &&
        <FilterItem label="Industry" icon="industry" state={filterIndustry} setState={setFilterIndustry}
        isActive={filterIndustry?.length > 0} options={OPTIONS_INDUSTRY} menuPlacement="top"
        placeholder="Enter industries" />}
        
        {filterLocationPerson && <FilterItem label="Location" icon="user-location" state={filterLocationPerson}
        setState={setFilterLocationPerson} isActive={filterLocationPerson?.length > 0} options={OPTIONS_LOCATION}
        placeholder="Enter locations" />}
        
        {/* {filterCompanyHeadcount &&
        <FilterItem label="Company Headcount" icon="people" state={filterCompanyHeadcount}
          setState={setFilterCompanyHeadcount} isActive={filterCompanyHeadcount?.length > 0}
          placeholder="Choose headcount" isSearchable={false} menuPlacement="top"
          options={OPTIONS_COMPANY_HEADCOUNT} />
        } */}
      </ul>
    </>
  );
};
export default AnalyticsFilters;
const FilterItem: FC<any> = ({ type = "select", state, setState, label, labelInput, icon, isActive, options, placeholder, isSearchable = true, isMulti = true, menuPlacement = "auto", inputType= "text" as const }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string | number>("");
  useEffect(() => {
    if (state === searchInput || type !== "input") return;
    setSearchInput(state as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <li
      className={`${isActive ? "is_active" : ""} ${isOpen ? "is_open" : ""}`}
      onClick={() => {
        if (!isOpen) setIsOpen(!isOpen);
      }}
    >
      <div className="content-title flex items-center justify-evenly gap-2" onClick={() => setIsOpen(!isOpen)}>
        <div className="w-full flex items-center gap-2">
          <Icon name={icon} size={20} />
          {label}
        </div>
        {isActive && (
          <Button
            size="xxs"
            onClick={(e: any) => {
              e.stopPropagation();
              setState([]);
            }}
          >
            {type === "input" ? 1 : state?.length}
            <Icon name="close" size={12} />
          </Button>
        )}
        <Icon name={isOpen ? "caret-up" : "caret-down"} size={15} />
      </div>
      {isOpen && (
        <div className="px-[20px] pt-0 pb-4">
          {type === "input" && (
            <form
              className="flex gap-2 items-center"
              onSubmit={(e) => {
                e.preventDefault();
                setState(searchInput);
              }}
            >
              <InputField type={inputType} variant="dark" placeholder={placeholder} size="small" value={state} onChange={(e: any) => setState(e.target.value)} />
              {/* <Button
                iconName="search"
                iconSize={16}
                size="xs"
                onClick={() => setSearchInput(searchInput)}
              >
                Search
              </Button> */}
            </form>
            // <InputDebounce
            //   value={state}
            //   variant="dark"
            //   sizeInput="small"
            //   onChange={(value) => setState(value)}
            //   placeholder={placeholder}
            // />
          )}
          {type === "select" && (
            <InputSelect id={label === "Seniority" ? "seniority-select" : ""} label={labelInput} options={options}
              state={state} setState={setState}  placeholder={placeholder} isSearchable={isSearchable}
              isMulti={isMulti} menuPlacement={menuPlacement} />
          )}
        </div>
      )}
    </li>
  );
};

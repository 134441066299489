import Button from "components/atoms/Button/Button";
import Loading from "components/atoms/Loading/Loading";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  getCompanyPageUrls,
  editCompanyPageUrl,
  addCompanyPageUrl,
  deleteCompanyPageUrl,
  resetEditCompanyPageUrlState,
  resetDeleteCompanyPageUrlState,
  resetGetCompanyPageUrlState,
  resetAddCompanyPageUrlState,
} from "store/slices/company.slice";
import styles from "./style.module.scss";
import Heading from "components/atoms/layout/Heading/Heading";
import Table from "components/atoms/Table/Table";
import Icon from "components/atoms/Icon/Icon";
import Popup from "../Popup/Popup";
import InputField from "components/atoms/InputField/InputField";
import { toast } from "react-toastify";
import { ToggleButton } from "components";
import Container from "../../atoms/layout/Container/Container";
const CompanyPages: React.FC = () => {
  const dispatch = useAppDispatch();
  const companyPages = useAppSelector((state) => state.company.companyPages.data);
  const isLoading = useAppSelector((state) => state.company.companyPages.isLoading);
  const {isLoading: isAddingPage, isSuccess: addSuccess } = useAppSelector((state) => state.company.addCompanyPages);
  const {isLoading: isPageEditing, isSuccess: editSuccess } = useAppSelector((state) => state.company.editCompanyPages);
  const { data: user } = useAppSelector((state) => state.user);
  const {isLoading: isPageDeleting, isSuccess: deleteSuccess} = useAppSelector((state) => state.company.deleteCompanyPages);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pageUrl, setPageUrl] = useState("");
  const [pageIntent, setPageIntent] = useState("low");
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [pageCondition, setPageCondition] = useState("contains");
  useEffect(() => {
    dispatch(resetGetCompanyPageUrlState());
    dispatch(getCompanyPageUrls());
  }, []);
  useEffect(() => {
    if (addSuccess) {
      toast.success("The page url has been successfully added");
      dispatch(resetAddCompanyPageUrlState());
    }
    if (deleteSuccess) {
      toast.success("The page url has been successfully removed");
      dispatch(resetDeleteCompanyPageUrlState());
    }
    if (editSuccess) {
      toast.success("The page url has been successfully modified");
      dispatch(resetEditCompanyPageUrlState());
    }
  },[editSuccess, deleteSuccess, addSuccess])
  useEffect(() => {
    if (!isPageDeleting) setIdToDelete(null)
    if (!isPageEditing) setEditingId(null)
  },[isPageDeleting, isPageEditing])
  const handleAddCompanyPageUrl = () => {
    setPageUrl("");
    setPageIntent("low");
    setIsPopupOpen(true);
    setIsEditing(false);
  };
  const extractUrl = (pageUrl: string): string => {
    if (pageCondition === "contains") {
      const url = new URL(pageUrl, process.env.REACT_APP_CLIENT_URL);
      return url.pathname;
    }
    return pageUrl;
  };
  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isEditing && editingId !== null) {
      dispatch(editCompanyPageUrl({ id: editingId, pageUrl: extractUrl(pageUrl), pageIntent }));
    } else {
      dispatch(addCompanyPageUrl({ pageUrl: extractUrl(pageUrl), pageIntent }));
    }
    setIsPopupOpen(false);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleEditCompanyPageUrl = (id: string, pageUrl: string | null, pageIntent: string | null) => {
    if (id !== null && pageIntent !== null && pageUrl !== null) {
      setEditingId(id);
      setPageCondition("is");
      setPageUrl(pageUrl);
      setPageIntent(pageIntent);
      setIsPopupOpen(true);
      setIsEditing(true);
    }
  };

  const handleDeleteCompanyPageUrl = () => {
    if (idToDelete) {
      dispatch(deleteCompanyPageUrl(idToDelete));
    }
    setShowConfirmModal(false);
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
    setIdToDelete(null);
  };

  const handleConfirmDelete = (id: string) => {
    setIdToDelete(id);
    setShowConfirmModal(true);
  };
  if(!user.hasVerifiedEmail) {
    return null;
  }

  return (
    <Container>
    <div className={`flex flex-col w-full gap-5`}>
      <div className="flex sm:gap-6"><div className="items-center flex gap-3 text-white-500">
        <Icon name="page" size={30} />
        <h1 className="font-bold capitalize text-2xl">Pages</h1>
      </div></div>
      <div className={`${styles.container_fields} bg-gray-900`}>
        <div className={styles.container_fields_actions}>
          <Button action="submit" iconName="plus" onClick={handleAddCompanyPageUrl} isProcessing={isAddingPage} isProcessingTitle={"Adding new page url..."}>Add new page url</Button>
        </div>
        {isLoading ? (
          <div className="flex items-center gap-3">
            <Loading height="38px" spinnerSize={25} />
          </div>
        ) : (
          <><Table>
            <thead><tr>
              <th className="!border-none !font-bold !normal-case !text-sm" align="left">
                <div className="flex gap-2 !text-gray-400">Page URL</div>
              </th>
              <th className="!border-none !font-bold !normal-case !text-sm">
                <div className="flex justify-center gap-2 !text-gray-400">Intent</div>
              </th>
              {/* <th className="!border-none !font-bold !normal-case !text-sm">
                <div className="flex justify-center gap-2">
                  <div className="!text-gray-400">Views</div>
                </div>
              </th> */}
              <th className="!border-none !font-bold !normal-case !text-sm"></th>
            </tr></thead>
            <tbody>
              {companyPages.length && companyPages.map((page) => {
                return (
                  <tr key={page.clientPage.id}>
                    <td>
                      <div className={"badge primary font-bold min-w-[35px] sm off"}>{page.clientPage?.pageUrl}</div>
                    </td>
                    <td align="center">
                      <div className={"badge primary min-w-[35px] sm off"}>{page.clientPage?.pageIntent}</div>
                    </td>
                    <td align="center">
                      <div className="flex justify-center gap-4">
                        {(isPageDeleting || isPageEditing) && [idToDelete, editingId].some(el => el === page.clientPage?.id) 
                          ? <Loading height="23px" width="auto" color="white" spinnerSize={20} /> 
                          : <>
                              <button
                                  onClick={() =>
                                      handleEditCompanyPageUrl(
                                          page.clientPage?.id,
                                          page.clientPage?.pageUrl,
                                          page.clientPage?.pageIntent
                                      )}
                              >
                                <Icon name="edit" size={15} />
                              </button>
                              <button onClick={() => handleConfirmDelete(page.clientPage?.id)}><Icon name="trash" size={15} /></button>
                          </>
                        }
                      </div>
                    </td>
                  </tr>
              )})}
            </tbody></Table>
            {!companyPages.length && (
              <div className="flex pt-4 pb-2 w-full gap-3 items-center justify-center h-full">
                <Icon color={"#fff"} name="pageNotFound" size={28} />
                <h1 className="text-base">No pages found</h1>
              </div>
            )}
        </>)}
        {isPopupOpen && (
          <Popup noButton closeOnOutsideClick={false} onClose={handleClosePopup}>
            <Heading icon="page" title={isEditing ? "Edit page url" : "Add new page url"} />
            <div className="w-full text-left">
              <ToggleButton
                state={pageCondition}
                setState={setPageCondition}
                items={[
                  {
                    label: "Contains",
                    value: "contains",
                    icon: "",
                    onClick: () => setPageCondition("contains"),
                    isSelected: pageCondition === "contains",
                  },
                  {
                    label: "Is",
                    value: "is",
                    icon: "",
                    onClick: () => setPageCondition("is"),
                    isSelected: pageCondition === "is",
                  },
                ]}
              />
              <InputField
                type="text"
                name="Page url"
                placeholder={`Enter the url ${pageCondition === "contains" ? "containing the" : "exactly as the"} page path`}
                value={pageUrl}
                autoFocus={true}
                onChange={(e: any) => setPageUrl(e.target.value)}
                className="mt-2"
              />
              <p className="pt-2">{isEditing ? "Edit page intent" : "Select the page intent"}</p>
              <div className="flex py-4">
                <ToggleButton
                  state={pageIntent}
                  setState={setPageIntent}
                  items={[
                    {
                      label: "Low",
                      value: "low",
                      icon: "",
                      onClick: () => setPageIntent("low"),
                      isSelected: pageIntent === "low",
                    },
                    {
                      label: "Medium",
                      value: "medium",
                      icon: "",
                      onClick: () => setPageIntent("medium"),
                      isSelected: pageIntent === "medium",
                    },
                    {
                      label: "High",
                      value: "high",
                      icon: "",
                      onClick: () => setPageIntent("high"),
                      isSelected: pageIntent === "high",
                    },
                  ]}
                />
              </div>
              <Button action="submit" onClick={handleFormSubmit}>
                {isEditing ? "Save Changes" : "Submit"}
              </Button>
            </div>
          </Popup>
        )}
        {showConfirmModal && (
          <Popup noButton closeOnOutsideClick={false} onClose={handleCancelDelete}>
            <div className="flex items-center justify-center z-50">
              <div className="p-6 rounded-lg">
                <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
                <p className="mb-4">Are you sure you want to delete this page URL?</p>
                <div className="flex justify-end">
                  <Button className="bg-red-500 text-white-500 px-4 py-2 rounded mr-2" onClick={handleDeleteCompanyPageUrl}>
                    Yes
                  </Button>
                  <Button className="bg-gray-400 text-white-500 px-4 py-2 rounded" onClick={handleCancelDelete}>
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Popup>
        )}
      </div>
    </div>
  </Container>
  );
};

export default CompanyPages;

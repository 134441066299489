import React, { useState } from "react";
import styles from "./style.module.scss";
import DragAndDropUpload from "components/atoms/DragAndDropUpload/DragAndDropUpload";
import AzureBlobDownloader from "../AzureDownload(notused)/AzureDownload";
import BulkLead from "../BulkLead/BulkLead";

const templateLink = "https://middlewarexdevxstorage.blob.core.windows.net/dev/export_a88e4c6b-4570-4152-be08-f2defc8e97d0.csv";

const BulkSearch: React.FC = () => {
  const [listName, setListName] = useState<string>("");
  const [uploadState, setUploadState] = useState<boolean>(false);
  const [CSVObject, setCSVObject] = useState([]);
  const [uploadFiles, setUploadFiles] = useState(null);
  const handleUpload = ({ files, parsed, parsedCSV }: any) => {
    setUploadState(parsed);
    setCSVObject(parsedCSV);
    setListName(files.name)    
    setUploadFiles(files);
  };

  return (
    <div className={styles.container}>
      <h1 className="text-xl">Bulk Search</h1>
      <div className={styles.container_heading}>
        <p className="text-base">Upload your file in <span style={{marginLeft: 5}} className="badge off">CSV format</span></p>
        <div className="hidden sm:table-cell">
          <AzureBlobDownloader blobName={`${templateLink}`}>Download a template</AzureBlobDownloader>
        </div>
      </div>
      {!uploadState ? (
        <div className={styles.container_content}>
          <div className={styles.container_content_upload}>
            <DragAndDropUpload handleUpload={handleUpload} />
            <div className="md:hidden">
              <AzureBlobDownloader blobName={`${templateLink}`}>Download a template</AzureBlobDownloader>
            </div>
          </div>
        </div>
      ) : (
        <BulkLead
          file={uploadFiles}
          listName={listName}
          CSVObject={CSVObject}
          uploadState={setUploadState}
        />
      )}
    </div>
  );
};

export default BulkSearch;

import Payment from "payment";
export default class PaymentHelper {
    private static clearNumber(value = "") {
        return value.replace(/\D+/g, "");
    }
    static formatCreditCardNumber(value: any) {
        if (!value) return value;

        const issuer = Payment.fns.cardType(value);
        const clearValue = this.clearNumber(value);
        let nextValue;

        switch (issuer) {
            case "amex":
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
            break;
            case "dinersclub":
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4,10)} ${clearValue.slice(10, 14)}`;
            break;
            default:
                nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4,8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
        }

        return nextValue.trim();
    }
    static formatCVC(value: any, allValues: any = {}) {
        const clearValue = this.clearNumber(value);
        let maxLength = 4;
        if (allValues.number) {
            const issuer = Payment.fns.cardType(allValues.number);
            maxLength = issuer === "amex" ? 4 : 3;
        }
        return clearValue.slice(0, maxLength);
    }
    static formatExpirationDate(value: any) {
        const clearValue = this.clearNumber(value);
        if (clearValue.length >= 3) {
            return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
        }
        return clearValue;
    }
    static isPaymentCardValid(card: { number: string; expiry: string; cvc: string }): boolean {
        return Payment.fns.validateCardNumber(card.number) && 
               Payment.fns.validateCardExpiry(card.expiry) && 
               Payment.fns.validateCardCVC(card.cvc)
    }
    // static encryptCardDetails(
    //     number: string,
    //     expiry: string,
    //     cvc: string,
    //     name: string,
    //     platform: PlatformBrandName
    // ) {
    //     return {
    //         number: CryptoOperations.createJwt(number, platform),
    //         expiry: CryptoOperations.createJwt(expiry, platform),
    //         cvc: CryptoOperations.createJwt(cvc, platform),
    //         name: CryptoOperations.createJwt(name, platform)
    //     }
    // }
}
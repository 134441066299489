import React, { CSSProperties, ReactNode, useEffect } from "react";
import theme from "../../../assets/themes";

const ThemeProvider: React.FC<{ children: ReactNode, className:string }> = ({ children, className }) => {
    useEffect(() => {
        document.body.style.setProperty("--bg-color", theme["--bg-color"], "important")
        // document.body.style.setProperty("--scrollbar-background", theme["--scrollbar-background"], "important")
        // document.body.style.setProperty("--scrollbar-hover-color", theme["--scrollbar-hover-color"], "important")
    },[])
  return <div className={className} style={theme as CSSProperties}>{children}</div>;
};
export default ThemeProvider;
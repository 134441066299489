import React from "react";
import styles from "./style.module.scss";
import Button from "components/atoms/Button/Button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { uploadData } from "store/slices/list.slice";
import Loading from "components/atoms/Loading/Loading";
import { toast } from "react-toastify";
import BusinessRulesHelper from "helpers/BusinessRulesHelper";
import Icon from "components/atoms/Icon/Icon";
import Tooltip from "components/atoms/Tooltip/Tooltip";

const BulkLead: React.FC<any> = ({file, CSVObject, uploadState, listName}) => {
  const { isLoading, isSuccess } = useAppSelector((state) => state.lists.uploadData)
  const { data: user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const uploadFile = () => {
    if (!BusinessRulesHelper.hasEnoughCredits(user)) return toast.error("Please check your balance");
    if (!file) return;
    const data = new FormData();
    data.append("csv", file);
    data.append("listName", listName);
    dispatch(uploadData(data))
  };
  const getFileName: (name: string) => string = (name) => name.length > 25 ? `${name.substring(0, 25)}...` : name;
  return (
    <div className={styles.main}>
      <div className={styles.main_container}>
        {isLoading 
          ? <div className={styles.main_container_file}>
              <Loading height="35px" spinnerSize={30} />
              <p className={styles.main_loadingProcess_text_title}>Please wait, your file is being processed...</p>
            </div>
          : <>
            <div className={styles.main_container_file}>
              <Icon name="document" size={40} className="text-green-500" />
              <p className={styles.main_container_file_name}>
                {getFileName(file?.name) || "-"}
              </p>
              <p className={styles.main_container_file_emails}>
                {CSVObject?.length || 0} entries found
              </p>
            </div>
            <div
              className={styles.main_container_close}
              onClick={() => uploadState(false)}
            >
              <Tooltip content="Remove File">
                <span style={{paddingTop: 5}}><Icon name="trash" size={16} /></span>
              </Tooltip>
            </div>
          </>}
      </div>
      {!isSuccess ? (
        <Button 
          onClick={()=>{
            if (!isLoading) uploadFile()
          }}
          iconName={!isLoading ? "search" : "download"}
        >
          {isLoading ? "Uploading..." : "Start"}
        </Button>
      ) : (
        <Button iconName="download">Download</Button>
      )}
    </div>
  );
};

export default BulkLead;

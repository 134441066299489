export enum ClientICPFilterOperatorENUM {
    IS_REQUIRED="12101",
    // CONTAINS="12102",
}

const CLIENT_ICP_FILTER_OPERATOR_OPTIONS = [
    { label: "Is Known", value: ClientICPFilterOperatorENUM.IS_REQUIRED },
    // { label: "Contains", value: ClientICPFilterOperatorENUM.CONTAINS },
]

export const OPTIONS_JOBTITLE = [
    { label: "CEO", value: "CEO" },
    { label: "Director", value: "Director" },
    { label: "President", value: "President" },
    { label: "Owner", value: "Owner" },
    { label: "Manager", value: "Manager" },
    { label: "Supervisor", value: "Supervisor" },
    { label: "Consultant", value: "Consultant" },
    { label: "Project Manager", value: "Project Manager" },
    { label: "Engineer", value: "Engineer" },
    { label: "Software Engineer", value: "Software Engineer" },
    { label: "Sales", value: "Sales" },
]

export const GROUPED_OPTIONS_JOBTITLE = [
    { label: "General", options: CLIENT_ICP_FILTER_OPERATOR_OPTIONS },
    { label: "Job titles", options: OPTIONS_JOBTITLE }
]

export const OPTIONS_GENDER = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
]
export const OPTIONS_COMPANY = [
    { label: "Google", value: "Google" },
    { label: "Facebook", value: "Facebook" },
    { label: "Amazon", value: "Amazon" },
    { label: "Apple", value: "Apple" },
    { label: "Microsoft", value: "Microsoft" },
    { label: "Netflix", value: "Netflix" },
    { label: "Tesla", value: "Tesla" },
    { label: "Twitter", value: "Twitter" },
    { label: "Uber", value: "Uber" },
    { label: "Airbnb", value: "Airbnb" },
]

export const GROUPED_OPTIONS_COMPANY = [
    { label: "General", options: CLIENT_ICP_FILTER_OPERATOR_OPTIONS },
    { label: "Company names", options: OPTIONS_COMPANY }
]

export const OPTIONS_COMPANY_HEADCOUNT = [
    { label: "1 to 10", value: "1-10" },
    { label: "11 to 50", value: "11-50" },
    { label: "51 to 200", value: "51-200" },
    { label: "201 to 500", value: "201-500" },
    { label: "501 to 1000", value: "501-1000" },
    { label: "1001 to 5000", value: "1001-5000" },
    { label: "5001 to 10000", value: "5001-10000" },
    { label: "10000+", value: "10000+" },
    // 5x5
    // { label: "1 to 10", value: "1 to 10" },
    // { label: "11 to 25", value: "11 to 25" },
    // { label: "26 to 50", value: "26 to 50" },
    // { label: "51 to 100", value: "51 to 100" },
    // { label: "101 to 250", value: "101 to 250" },
    // { label: "251 to 500", value: "251 to 500" },
    // { label: "501 to 1000", value: "501 to 1000" },
    // { label: "1001 to 5000", value: "1001 to 5000" },
    // { label: "5001 to 10000", value: "5001 to 10000" },
    // { label: "10000+", value: "10000+" },
]
export const OPTIONS_COMPANY_REVENUE = [
    { label: "Under 1 Million", value: "Under 1 Million" },
    { label: "1 Million to 5 Million", value: "1 Million to 5 Million"},
    { label: "5 Million to 10 Million", value: "5 Million to 10 Million" },
    { label: "10 Million to 25 Million", value: "10 Million to 25 Million" },
    { label: "25 Million to 50 Million", value: "25 Million to 50 Million" },
    { label: "50 Million to 100 Million", value: "50 Million to 100 Million" },
    { label: "100 Million to 250 Million", value: "100 Million to 250 Million"},
    { label: "250 Million to 500 Million", value: "250 Million to 500 Million" },
    { label: "500 Million to 1 Billion", value: "500 Million to 1 Billion" },
    { label: "1 Billion and Over", value: "1 Billion and Over" },
]
/*
    - Found in:
    consumer goods, mechanical or industrial engineering, pharmaceuticals, , hospitality, government administration, , utilities
    marketing and advertising, research, telecommunications, machinery, insurance,
    business supplies and equipment, staffing and recruiting, , , , , , semiconductors, medical devices, consumer electronics, mining & metals, public safety
     computer software, military, renewables & environment, wholesale, building materials, defense & space,  management consulting, industrial automation, internet
    - Used in:
    information technology and services, real estate, restaurants, higher education, entertainment, automotive, transportation/trucking/railroad, construction, hospital & health care,
    apparel & fashion, civic & social organization, design, music, electrical/electronic manufacturing, leisure, travel & tourism
    law practice, law enforcement, banking, financial services, oil & energy, architecture & planning, non-profit organization management, Sports
*/
export const OPTIONS_INDUSTRY = [
    { label: "Finance", value: "financial services" },
    { label: "Healthcare", value: "health" },
    { label: "Architecture", value: "architecture" },
    // { label: "Retail", value: "Retail" },
    // { label: "Agriculture", value: "Agriculture" },
    { label: "Marketing", value: "marketing" },
    { label: "Automotive", value: "automotive" },
    { label: "Restaurants", value: "restaurants" },
    { label: "Education", value: "education" },
    { label: "Real Estate", value: "real estate" },
    { label: "Entertainment", value: "entertainment" },
    { label: "Manufacturing", value: "manufacturing" },
    { label: "Energy", value: "energy" },
    { label: "Music", value: "music" },
    { label: "Fashion", value: "fashion" },
    { label: "Transportation", value: "transportation" },
    { label: "Non-profit Organization", value: "non-profit" },
    { label: "Banking", value: "banking" },
    { label: "Construction", value: "construction" },
    { label: "Sports", value: "sports" },
    { label: "Tourism", value: "tourism" },
    { label: "Design", value: "design" },
    { label: "Law", value: "law" },
    { label: "Technology", value: "technology" },
    { label: "Civic & Social Organization", value: "civic & social organization" },
]
export const OPTIONS_LOCATION = [
    { label: "United States", value: "United States" },
    { label: "Alabama", value: "Alabama" },
    { label: "Alaska", value: "Alaska" },
    { label: "Arizona", value: "Arizona" },
    { label: "Arkansas", value: "Arkansas" },
    { label: "California", value: "California" },
    { label: "Colorado", value: "Colorado" },
    { label: "Connecticut", value: "Connecticut" },
    { label: "Delaware", value: "Delaware" },
    { label: "Florida", value: "Florida" },
    { label: "Georgia", value: "Georgia" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "Idaho", value: "Idaho" },
    { label: "Illinois", value: "Illinois" },
    { label: "Indiana", value: "Indiana" },
    { label: "Iowa", value: "Iowa" },
    { label: "Kansas", value: "Kansas" },
    { label: "Kentucky", value: "Kentucky" },
    { label: "Louisiana", value: "Louisiana" },
    { label: "Maine", value: "Maine" },
    { label: "Maryland", value: "Maryland" },
    { label: "Massachusetts", value: "Massachusetts" },
    { label: "Michigan", value: "Michigan" },
    { label: "Minnesota", value: "Minnesota" },
    { label: "Mississippi", value: "Mississippi" },
    { label: "Missouri", value: "Missouri" },
    { label: "Montana", value: "Montana" },
    { label: "Nebraska", value: "Nebraska" },
    { label: "Nevada", value: "Nevada" },
    { label: "New Hampshire", value: "New Hampshire" },
    { label: "New Jersey", value: "New Jersey" },
    { label: "New Mexico", value: "New Mexico" },
    { label: "New York", value: "New York" },
    { label: "North Carolina", value: "North Carolina" },
    { label: "North Dakota", value: "North Dakota" },
    { label: "Ohio", value: "Ohio" },
    { label: "Oklahoma", value: "Oklahoma" },
    { label: "Oregon", value: "Oregon" },
    { label: "Pennsylvania", value: "Pennsylvania" },
    { label: "Rhode Island", value: "Rhode Island" },
    { label: "South Carolina", value: "South Carolina" },
    { label: "South Dakota", value: "South Dakota" },
    { label: "Tennessee", value: "Tennessee" },
    { label: "Texas", value: "Texas" },
    { label: "Utah", value: "Utah" },
    { label: "Vermont", value: "Vermont" },
    { label: "Virginia", value: "Virginia" },
    { label: "Washington", value: "Washington" },
    { label: "West Virginia", value: "West Virginia" },
    { label: "Wisconsin", value: "Wisconsin" },
    { label: "Wyoming", value: "Wyoming" },
]
export const OPTIONS_SENIORITY = [
    { label: "Training", value: "training" },
    { label: "Owner", value: "owner" },
    { label: "Director", value: "director" },
    { label: "Manager", value: "manager" },
    { label: "CXO", value: "cxo" },
    { label: "Vice President", value: "vp" },
    { label: "Senior", value: "senior" },
    { label: "Partner", value: "partner" },
    { label: "Unpaid", value: "unpaid" },
    // { label: "Staff", value: "Staff" },
    // { label: "President", value: "president" },
    // { label: "Junior", value: "Junior" },
]
export const OPTIONS_DEPARTMENT = [
    // { label: "Executive", value: "Executive" },
    // { label: "Administrative", value: "Administrative"},
    { label: "Engineering", value: "engineering" },
    { label: "Sales", value: "sales" },
    { label: "Public Relations", value: "public_relations" },
    { label: "Marketing", value: "marketing" },
    { label: "Customer Service", value: "customer_service" },
    { label: "Human Resources", value: "human_resources" },
    { label: "Finance", value: "finance" },
    { label: "Operations", value: "operations" },
    { label: "Legal", value: "legal" },
    // { label: "Product", value: "Product" },
    // { label: "Research", value: "Research" },
    // { label: "Development", value: "Development" },
    { label: "Design", value: "design" },
    // { label: "Government", value: "Government" },
    // { label: "Quality Assurance", value: "Quality Assurance" },
    // { label: "Information Technology", value: "Information Technology" },
    // { label: "Community And Social Services", value: "Community And Social Services" },
    // { label: "Military And Protective Services", value: "Military And Protective Services" },
    { label: "Media And Communications", value: "media" },
    // { label: "Product Management", value: "Product Management" },
    // { label: "Supply Chain", value: "Supply Chain" },
    // { label: "Logistics", value: "Logistics" },
    { label: "Education", value: "education" },
    { label: "Health Services", value: "health" },
    // { label: "Nonprofit", value: "Nonprofit" },
    { label: "Real Estate", value: "real_state" },
]
export const OPTIONS_INCOME_RANGE = [
    { label: "Less than $20,000", value: "Less than $20,000" },
    { label: "$20,000 to $44,999", value: "$20,000 to $44,999" },
    { label: "$45,000 to $59,999", value: "$45,000 to $59,999" },
    { label: "$60,000 to $74,999", value: "$60,000 to $74,999" },
    { label: "$75,000 to $89,999", value: "$75,000 to $89,999" },
    { label: "$90,000 to $119,999", value: "$90,000 to $119,999"},
    { label: "$120,000 to $149,999", value: "$120,000 to $149,999" },
    { label: "$150,000 to $199,999", value: "$150,000 to $199,999" },
    { label: "$200,000 to $249,000", value: "$200,000 to $249,000" },
    { label: "$250,000+", value: "$250,000+" },
]

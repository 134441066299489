import React, {useEffect} from "react";
import styles from "./style.module.scss";
import Button from "components/atoms/Button/Button";
import { useNavigate } from "react-router-dom";


const Error: React.FC = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    const token = localStorage.getItem("accessToken");
    navigate(token ? "/dashboard" : "/welcome");
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) navigate("/dashboard")
      window.analytics.page("Page Not Found")
  }, [])
  
  
  return (
    <div className={styles.container}>
      <h1>404</h1>
      <p>Page not found</p>
      <Button onClick={() => handleClick()}>Go Home</Button>
    </div>
  );
};

export default Error;

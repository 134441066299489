import axios from "../../../utils/axios";
import Button from "components/atoms/Button/Button";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Popup from "../Popup/Popup";
import styles from './style.module.scss'
import Loading from "components/atoms/Loading/Loading";
const baseUrl = process.env.REACT_APP_BASE_URL;

const AzureBlobDownloader: React.FC<{
  blobName: string;
  loading?: boolean;
  children:string;
  listId?: string;
}> = ({ blobName, loading, children, listId }) => {
  const [showPopup, setShowPopup] = useState(false);
  const downloadFile = (content: any, fileName: any) => {
    const blob = new Blob([content], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const downloadBlob = async () => {
    try {
      let resp
      if (listId) {
        resp = await axios.get(`${baseUrl}bulk_lead_search?id=${listId}`);
      } else {
        resp = await axios.get(
          `${baseUrl}lead/fetch/item_bulk_template`,
          {
            // blobName: blobName.substring(blobName.lastIndexOf("/") + 1),
          },
        );
      }
      downloadFile(
        resp.data,
        blobName.substring(blobName.lastIndexOf("/") + 1)
      );
    } catch (err: any) {
      toast.error(err.response.data);
    }
  };

  useEffect(() => {
    if (!loading) {
      setShowPopup(false)
    }
  },[loading])

  return (
    <>
      <Button
        iconName="download"
        type="download"
        size="sm"
        onClick={() => (!loading ? downloadBlob() : setShowPopup(true))}
      >
        {children}
      </Button>
      {showPopup && (
        <Popup
          onClose={() => setShowPopup(false)}
          type="modal"
          bodyClass={styles.popup}
          buttonText="Close"
        >
          <p>Your data is still processing</p>
          <Loading height='max-content'/>
        </Popup>
      )}
    </>
  );
};

export default AzureBlobDownloader;

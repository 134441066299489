
export enum EnrichmentOrigin {
    IDENTITY_MATRIX = 1,
    TRAFFIC_INTEL = 2,
    BULK_ENRICHMENT = 3,

    SINGLE_ENRICHMENT_EMAIL = 4,
    SINGLE_ENRICHMENT_LINKEDIN = 5,
    SINGLE_ENRICHMENT_PHONE = 6,
    SINGLE_ENRICHMENT_NAME_COMPANY = 7,
    SINGLE_ENRICHMENT_ADDRESS = 8,

    SINGLE_ENRICHMENT_ALL = 100
}
export enum EnrichmentStatus {
    SUCCESS = "success",
    PROCESSING = "processing",
    FAILURE = "failure",
    ERROR = "error",
}

export enum EnrichmentContactRevealSourceENUM { SEARCH = "search", PIXEL = "pixel" }


import Button from "components/atoms/Button/Button";
import Loading from "components/atoms/Loading/Loading";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  createCompanyPixel,
  getCompanyPixel,
  resetCompanyPixelState,
  resetAddCompanyPixelState,
} from "store/slices/company.slice";
import { RootState } from "store/store";
import styles from "./style.module.scss";
import { toast } from "react-toastify";
import Heading from "components/atoms/layout/Heading/Heading";
import PixelHelper from "helpers/PixelHelper";
import { CopyBlock, a11yDark as themeBlock } from "react-code-blocks";

const CompanyPixel: React.FC = () => {
  const dispatch = useAppDispatch();
  const companyPixel = useAppSelector((state: RootState) => state.company.companyPixel);
  const {isSuccess, isLoading} = useAppSelector((state) => state.company.companyPixel);
  const {isSuccess: isAddSuccess, errorMessage: addErrorMessage, isLoading: isAddLoading } = useAppSelector((state) => state.company.addCompanyPixel);
  useEffect(() => {
    dispatch(resetCompanyPixelState());
    dispatch(resetAddCompanyPixelState());
    dispatch(getCompanyPixel());
  }, [dispatch]);
  const handleCreatePixel = () => { dispatch(createCompanyPixel()); };
  const handleCopyToClipboard = () => {
    if (companyPixel.data?.trackingCode) {
      navigator.clipboard.writeText(PixelHelper.generateScript(companyPixel));
      toast.success("Your tracking pixel has been successfully copied to the clipboard");
  }}
  return <div className={`flex flex-col w-full gap-5`}>
    <Heading icon="code" iconSize={33} title="Your Tracking Pixel" />
    <div className={styles.container_fields}>
      {isLoading 
      ? <div className="flex items-center gap-3"><Loading height="38px" spinnerSize={32} /></div>
      : (isSuccess || isAddSuccess) && companyPixel.data?.trackingCode 
        ? <>
            <CopyBlock
              theme={themeBlock} language="html" showLineNumbers={false} wrapLongLines
              customStyle={{
                "padding": "8px 10px",
                "fontSize": "15px",
                "overflow": "hidden",
                "textOverflow": "ellipsis",
                "whiteSpace": "nowrap",
              }}
              text={PixelHelper.generateScript(companyPixel)}
              onCopy={() => toast.success("Your tracking pixel has been successfully copied to the clipboard")}
            />
            <div className={styles.container_fields_actions}>
              <Button iconName="code" action="submit" onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
            </div>
        </> 
        : <>
          <p>You don't have a pixel generated yet.</p>
          <div className={styles.container_fields_actions}>
            <Button action="submit" onClick={handleCreatePixel} isProcessing={isAddLoading}>Create Pixel</Button>
          </div>
        </>
      }
      {addErrorMessage && <p>Error: {addErrorMessage}</p>}
    </div>
  </div>
};

export default CompanyPixel;

import Button from "components/atoms/Button/Button";
import Icon from "components/atoms/Icon/Icon";
import InputField from "components/atoms/InputField/InputField";
import Loading from "components/atoms/Loading/Loading";
import Heading from "components/atoms/layout/Heading/Heading";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateWebhookURL } from "store/slices/client.slice";
import { isURL } from "validator";

const CrmContainer: React.FC = () => {
    const { data: user, isLoading: IsLoadingUser } = useAppSelector((state) => state.user);
    const { data: client, isLoading: IsloadingClient } = useAppSelector((state) => state.client);
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState<string>('');
    const [isValidURL, setIsValidURL] = useState<boolean>(true);
    useEffect(() => {
        if (client) {
            setInputValue(client.clayWebhookUrl || '');
        }
    }, [client?.clayWebhookUrl])

    const onChangeInput = (e:  React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setIsValidURL(true);
    }

    const onSave = async () => {
        const isValidURL = validateOnSave();

        if (!isValidURL) return setIsValidURL(false);
        
        try {
            const data = await  dispatch(updateWebhookURL({clientId: user.clientId, clayWebhookUrl: inputValue})).unwrap();
            if (data.isError) throw new Error(data.message);

            toast.success('Clay webhook URL was successfully set!')
        } catch (err: any) {
            toast.error(err.message || 'Some error has occured!')
        }
    }
    const onRemove = async () => {
        try {
            const data = await dispatch(updateWebhookURL({clientId: user.clientId, clayWebhookUrl: null})).unwrap();

            if (data.isError) throw new Error(data.message);

            toast.success('Clay webhook URL was successfully removed!')
        } catch  (err: any) {
            toast.error(err.message || 'Some error has occured!');
        }
    }
    const validateOnSave = (): boolean => isURL(inputValue, {protocols: ['https'], require_valid_protocol: true})
        
    const isDisabled = user.userRole !== 'admin';
    const isDisabledSave = (client?.clayWebhookUrl === null && !inputValue) || inputValue === client?.clayWebhookUrl;
    const isDisabledRemove =  !client?.clayWebhookUrl;
    const isLoading  = IsLoadingUser || IsloadingClient;
    
    return <>
        <div className="flex flex-col p-5 gap-6 bg-gray-900 rounded-lg relative">
            <Heading icon="code" iconSize={37} title="Clay Webhook" className={isLoading ? 'opacity-0' : 'opacity-100'} description="Use a webhook to send revealed contacts to Clay."/>
            <div className={`flex flex-col gap-4 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
                <InputField 
                    disabled={isDisabled}
                    value={inputValue} 
                    variant={!isValidURL ? 'error' : undefined} 
                    onChange={onChangeInput} 
                    placeholder="Add your url here..." name="webhookURL" 
                    iconName="globe"
                />
                <div className="flex gap-4">
                    <Button onClick={onSave} iconName="save" size="sm" disabled={isDisabled || isDisabledSave}>Save</Button>
                    <Button 
                        size="sm" iconName="trash" iconSize={16} 
                        onClick={onRemove}
                        disabled={isDisabled || isDisabledRemove}
                        >Remove</Button>
                </div>
            </div>
                {isLoading && <div className=" absolute bottom-[35%] left-[46%]"><Loading width="64px" height="64px" spinnerSize={54}/></div>}
                {!isValidURL && <label htmlFor="webhookURL" className="text-base text-red-500 absolute bottom-[45px] right-[32px]">Invalid URL</label>}
                {isDisabled && <p className="absolute top-4 right-6 text-sm text-red-300">You are not allowed to edit</p>}
        </div>
    </>
}
export default CrmContainer
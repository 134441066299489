import React from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import EntryFormatter from "utils/EntryFormatter";

const InputField: React.FC<{
  iconName?: string;
  iconPosition?: "end" | "start";
  size?: "tiny" | "small" | "large";
  variant?: "dark" | 'error';
  name?: string;
  register?: any;
  errors?: any;
  type?: "text" | "email" | "number" | "password";
  onlyNumbers?: boolean;
  validationSchema?: any;
  placeholder?: string;
  className?: string;
  value?: any;
  style?: any;
  validate?: any;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  text?:string;
  disabled?: boolean;
  width?: "full" | "auto" | string;
  autoFocus?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
}> = ({
  iconName,
  iconPosition,
  size,
  name,
  register,
  errors,
  type = "text",
  validationSchema,
  onlyNumbers = false,
  placeholder,
  className,
  value,
  style,
  onChange, onFocus, onBlur,
  text,
  disabled,
  width = "full",
  autoFocus = false,
  maxLength,
  variant,
  min,
  max,
}) => {
  const divClass = classNames(
    styles.container,
    className,
    iconPosition === "end" && styles.container_reverse,
    styles[`container_${size}`],
    variant && styles[`container_${variant}`],
    errors && {
      [styles.container_input_error]: name && errors[name],
    }
  );
  return (
    <div style={style} className={`${width ==="auto" ?'w-auto' : width === 'full' ? 'w-full' : width } ${className || ``}`}>
      <div className={divClass}>
        {iconName && 
          <label className={styles.container_icon} htmlFor={name}>
            <Icon name={iconName} size={size === "tiny" ? 15 : 20} />
          </label>
        }
        {text && <p className={`${iconPosition === "end" ? "mr-2" : "ml-2"}`}>
          <span className="text-gray-300 text-sm">{text}</span>
        </p>}
        <input
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          autoFocus={autoFocus}
          type={type}
          className={styles.container_input}
          placeholder={placeholder}
          id={name} name={name}
          disabled={disabled}
          value={value}
          min={min}
          max={max}
          onChange={(e)=>{
            if (onlyNumbers) {
              const re = /^[0-9\b]+$/;
              if (e.target.value !== '' && !re.test(e.target.value)) return
            }
            if (onChange) onChange(e)
          }}
          {...(register && { ...register(name, validationSchema) })}
          // pattern="[0-9]*" type: 'number', pattern:"\d*"
        />
      </div>
      {!EntryFormatter.isObjectEmpty(errors) && (
        <span
          className={styles.container_input_error_message}
        >
          {name && errors[name]?.message}
        </span>
      )}
    </div>
  );
};
export default InputField;
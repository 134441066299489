import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button/Button";
import InputField from "components/atoms/InputField/InputField";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetSentinvite, sentinviteMessage } from "store/slices/user.slice";
import Heading from "components/atoms/layout/Heading/Heading";

const CompanyTeamInviteForm: React.FC<{
  closePopup: () => void;
}> = ({
  closePopup
}) => {
  const [email, setEmail] = useState("");
  const { errorMessage, isLoading, isSuccess, successMessage } = useAppSelector((state) => state.user.sendinviteMessage);

  const dispatch = useAppDispatch();

  const handleSendinvite: () => void = () => {
    const emailValidation =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      return toast.error("Please fill email address");
    } else if (!emailValidation.test(email)) {
      return toast.error("Please fill valid email address");
    }

    dispatch(sentinviteMessage(email));
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(successMessage);
      closePopup();
    } else if (errorMessage) {
      toast.error(String(errorMessage), {toastId: "inviteUserError"});
    }
    dispatch(resetSentinvite());
  }, [successMessage, errorMessage]);

  return (
    <div className="flex flex-col w-full gap-6">
      <Heading
        icon="people"
        title="Invite Team Member" 
        description="Add a member to your team."
      />
      <InputField
        iconName="email"
        name="email"
        disabled={isLoading}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        placeholder="Enter a team member email address"
      />
      <Button
        action="submit"
        iconName="paperplane" 
        onClick={handleSendinvite} 
        isProcessing={isLoading}
      >
        Invite User
      </Button>
    </div>
  );
};
export default CompanyTeamInviteForm;
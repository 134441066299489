import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { convertQueryString } from "utils/workWithData";

const baseUrl = process.env.REACT_APP_BASE_URL;
interface ISearchMain {
  isLoadingCount: boolean,
  isSuccessCount: boolean,
  isLoadingData: boolean,
  isSuccessData: boolean,
  leadsOnPage: LeadProfileDTO[],
  count: number,
  errorMessage: string | unknown,
}
const initialState: ISearchMain = {
  isLoadingCount: false,
  isSuccessCount: false,
  isLoadingData: false,
  isSuccessData: false,
  leadsOnPage: [],
  count: 0,
  errorMessage: "",
};
export const getSearchMainData = createAsyncThunk("data/getSearchMainData", async (queryParams: any, thunkAPI) => {
  try {
    const query = await convertQueryString(queryParams);
    const url = `${baseUrl}lead/history/pdl?${query}`;
    const response = await axios.get(url, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}})
export const getSearchMainCount = createAsyncThunk("data/getSearchMainCount", async (queryParams: any, thunkAPI) => {
  try {
    const query = await convertQueryString({...queryParams, request_type: 'count'});
    const url = `${baseUrl}lead/history/pdl?${query}`;
    const response = await axios.get(url, { withCredentials: true });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}})
export const searchMainSlice = createSlice({
  name: "search_main", initialState, reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSearchMainData.pending, (state) => {state.isLoadingData = true});
    builder.addCase(getSearchMainData.fulfilled, (state, action) => {
      state.isLoadingData = false;
      state.isSuccessData = true;
      state.leadsOnPage = action.payload.data || [];
    });
    builder.addCase(getSearchMainData.rejected, (state, action: any) => {
      state.isLoadingData = false;
      state.isSuccessData = true;
      state.errorMessage = action.payload?.message || "An error has occurred";
    });
    builder.addCase(getSearchMainCount.pending, (state) => {state.isLoadingCount = true});
    builder.addCase(getSearchMainCount.fulfilled, (state, action) => {
      state.isLoadingCount = false;
      state.isSuccessCount = true;
      state.count = action.payload.count || 0;
    });
    builder.addCase(getSearchMainCount.rejected, (state, action: any) => {
      state.isLoadingCount = false;
      state.isSuccessCount = true;
      state.count = 0
      state.errorMessage = action.payload?.message || "An error has occurred";
    });
  }
});


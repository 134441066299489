import React, { useState } from "react";
import styles from "./style.module.scss";
import {Icon, Menu} from "components";

const InputDropDown: React.FC<{ 
    menuArray: {
      title: string;
      onClick: Function,
      isSelected?: boolean
    }[], 
    triggerTitle: any 
  }> = (
    { menuArray, triggerTitle }
) => {
    const [anchor, setAnchor]= useState<null | HTMLElement>(null);
    const open = Boolean(anchor);
    return (<>
        <button 
            type="button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
            className={styles.dropdown}
            onClick={(e) => setAnchor(e.currentTarget)}
        >
            <div className="truncate text-sm">{triggerTitle}</div>
            <Icon name={open ?'chevron-up':'chevron-down'} size={12} />
        </button>
        <Menu
            items={menuArray} isOpen={open}
            anchor={anchor} setAnchor={setAnchor}
        />
    </>)
};

export default InputDropDown;
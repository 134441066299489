import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getTransactions, resetTransactionsSlice } from "store/slices/transactions.slice";
import Loading from "components/atoms/Loading/Loading";
import Table from "components/atoms/Table/Table";
import Button from "components/atoms/Button/Button";
import Icon from "components/atoms/Icon/Icon";
import { showDate } from "utils/workWithData";

const Transactions: React.FC = () => {
  const [page, setPage] = useState(2);
  const dispatch = useAppDispatch();
  const { data, errorMessage, isLoading, isLastPage } = useAppSelector((state) => state.transactions);

  useEffect(() => {
    dispatch( getTransactions({ page: 1, size: 10 }) );
    return () => {
      dispatch(resetTransactionsSlice())
    }
  }, []);

  const showMore = () => {
    dispatch( getTransactions({ page, size: 10 }) );
    setPage(page + 1);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.elements}>
          <div className="flex flex-col gap-3">
            <Table className={styles.elements_table}>
              <thead><tr>
                <th className="w25 text-left">Date</th>
                <th className="w10">
                  <div className="flex justify-center"><Icon name="credits" /></div>
                </th>
                <th className="w15">Balance</th>
                <th className="w50 hidden sm:table-cell">Action</th>
              </tr></thead>
              <tbody>
                {isLoading 
                  ? <tr><td className="!border-none" colSpan={4}><Loading height="100%" /></td></tr>
                  : errorMessage 
                    ? <tr><td className="!border-none" colSpan={4}><div className={`flex flex-col items-center gap-5 mt-10`}>
                      <Icon name="credits" size={50} />
                      <h3 className="text-base">No Transactions found</h3>
                    </div></td></tr>
                    : <>
                      {data.length > 0 
                        ? data.map((transaction, index) => {
                            const isPurchase = transaction.type === 1
                            const dateCreated = showDate(transaction.createdDate)
                            return <tr key={index}>
                                <td title={dateCreated} className="!text-xs">{dateCreated}</td>
                                <td className="text-center" style={{ color: isPurchase ? "green" : "red" }}>
                                  {(isPurchase ? `+` : ``) + transaction.creditAmount}
                                </td>
                                <td className="text-center">{transaction.creditBalanceAfter || "-"}</td>
                                <td className="text-center hidden sm:table-cell" title={transaction.note}>
                                  <div className="badge primary sm">{transaction.note}</div>
                                </td>
                            </tr>
                          })
                        : <tr>
                          <td className="!border-none" colSpan={4}><div className={`flex flex-col items-center gap-5 mt-10`}>
                            <Icon name="credits" size={50} />
                            <h3>No Transactions found</h3>
                          </div></td>
                        </tr>}
                    </>
                }
              </tbody>
            </Table>
            {(!isLastPage && !isLoading && !errorMessage) && (
              <div className={styles.elements_table_actions}>
                <Button size="sm" type="download" onClick={showMore}>+10 more</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
import React from "react";
import styles from "./style.module.scss";
import { PaymentPlanType } from "utils/enums/paymentENUM";

interface SubscriptionOptionInterface {
  price: number;
  oneTimePrice?: number;
  frequency: PaymentPlanType;
  frequencyTitle: string;
  selectedOption: string;
  handleChangeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const SubscriptionOption = ({
  price,
  oneTimePrice,
  frequency,
  frequencyTitle,
  selectedOption,
  handleChangeRadio,
}: SubscriptionOptionInterface) => {
  const getDiscount = (price: number) => {
    if (!oneTimePrice) return 0;
    const discount = ((oneTimePrice - price) / oneTimePrice) * 100;
    return discount.toFixed(0);
  };
  const isActive = selectedOption === frequency;
  let priceTotal = frequency === PaymentPlanType.YEARLY ? price * 12 : price;
  let [dollars, cents] = parseFloat(priceTotal.toString()).toFixed(2).split('.');
  return (
    <div className={`${styles.container} ${isActive ? styles.container_active : ""}`}>
      <div className="flex flex-col gap-5">
        <span className="badge primary font-bold tracking-wide uppercase">{`${frequencyTitle}`}</span>
        <div className="flex gap-1 items-center">
          <span className="text-sm font-bold">$</span>
          <span className="text-5xl font-bold">{dollars}</span>
          {Number(cents) !== 0 && <span className="text-sm font-bold">{cents}</span>}
          <span className="text-xs font-extralight text-gray-400 pl-1">USD</span>
        </div>
        {oneTimePrice && <span className="badge green sm font-extralight">{getDiscount(price)}% discount</span>}
      </div>
      <input
        type="radio"
        className={styles.container_box_radio}
        onChange={(e) => handleChangeRadio(e)}
        value={frequency}
        checked={selectedOption === frequency}
        name={frequency}
      />
    </div>
  )
};

export default SubscriptionOption;

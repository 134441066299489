import React, { useState } from "react";
import styles from "./style.module.scss";
import { csvReader } from "utils/workWithData";
import Icon from "../Icon/Icon";

const DragAndDropUpload: React.FC<any> = ({ handleUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDragOver(true);
  };
  const handleDragLeave = () => {
    setIsDragOver(false);
  };
  const handleDrop = async (e: any) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files[0];
    // Handle dropped files here
    if (files) {
      const data = await csvReader(files);
      if (data) {
        return handleUpload({
          files,
          parsed: true,
          parsedCSV: data,
        });
      }
      handleUpload({ files, parsed: false, parsedCSV: [] });
    }
  };
  const uploadWithoutDrag = async (files: any) => {
    if (!files) return
    const data = await csvReader(files);
    if (data) {
      return handleUpload({
        files,
        parsed: true,
        parsedCSV: data,
      });
    }
    return handleUpload({ files, parsed: false, parsedCSV: [] });
  };
  return (
    <label
      htmlFor="upload"
      className={`${styles.fileUpload} ${
        isDragOver ? `${styles.dragOver}` : ""
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        type="file"
        name="upload"
        id="upload"
        accept=".csv"
        className={styles.fileInput}
        onChange={(e) => uploadWithoutDrag(e.currentTarget.files && e.currentTarget.files[0])}
        onClick={(event: any) => (event.target.value = null)}
      />
      <div className="gap-2 flex flex-col">
        <div className={styles.fileUpload_text}>
          <Icon name="folder" />
          <p>
            <span className="text-primary-500">Click to upload</span> or drag and drop your CSV file
          </p>
          <p className={styles.fileUpload_text_mobile}>Click to upload</p>
        </div>
      </div>
      <div className="badge primary flex"><Icon name="download" size={22} /> Upload</div>
    </label>
  );
};

export default DragAndDropUpload;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks"
import EntryFormatter from "utils/EntryFormatter";

export const useChameleonIdentify = () => {
   const {data: user, isSuccess}  = useAppSelector(state => state.user);
   const location = useLocation()
   useEffect(() => {
      if (!EntryFormatter.isObjectEmpty(user)) {
         window.analytics.identify(user.userId, {
           email: user.email,
           firstName: user.firstName,
           lastName: user.lastName,
           company: EntryFormatter.exists(user.organization)
               ? user.organization?.name
               : user.organizationName,
           companyId: EntryFormatter.exists(user.organization)
               ? user.organization?.id
               : null,
           creditBalance: user.creditBalance,
           creditPurchase: user.creditPurchase || 0,
           creditUsage: user.creditUsage || 0,
           revenue: user?.revenue || 0,
         },  {
            integrations: {
               "All": false,
               "Chameleon": true
            }
         })
      }
   }, [user, isSuccess, location.pathname])

}
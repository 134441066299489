import React from "react";
import Icon from "components/atoms/Icon/Icon";
interface EmailVerificationProps {
  bgColor?: boolean;
}
const EmailVerafication: React.FC<EmailVerificationProps> = ({ bgColor }) => {
  return (
    <div className={`text-white-500 text-center flex flex-col gap-3 justify-center items-center px-4 py-12 sm:py-24 w-full  m-auto ${bgColor && 'bg-gray-900 rounded-lg flex h-[76vh]'}`}>
      <Icon name="email" size={70} />
      <h1 className="text-xl mt-4">Please verify your email</h1>
      <p className="text-gray-400 text-base">
        In order to enjoy the full range of services we offer, please verify
        your email.
      </p>
    </div>
  );
};

export default EmailVerafication;

import React, { useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import Button from "../../components/atoms/Button/Button";
import { Container, Icon, Loading } from "components";
import { getAllSysBillingPlan } from "store/slices/sys.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useAppContext } from "utils/context/AppContext";
import { AppSchemeENUM } from "utils/enums/appENUM";


const BuyCreditsModule: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { app } = useAppContext();
  const {data: sysBillingPlans, isLoading } = useAppSelector((state) => state.sys.billingPlan);


  useEffect(() => {
    if (app.scheme === AppSchemeENUM.IDENTITY_MATRIX) navigate("/")
    window.analytics.page("Buy Credits")

    dispatch(getAllSysBillingPlan());
  }, []);

  
  return <Container>
    {isLoading
      ? <Loading height="auto" />
      : <>
        <div className="gap-5 flex flex-col text-center text-base">
          <h1 className="text-2xl font-bold tracking-wide">The <span className="text-primary-400">Simplest Pricing</span> You'll Ever See</h1>
          <p className="text-gray-300">We're sick of contracts and we know you are too, that's why <span className="text-primary-400">our pricing is simple</span>. No hidden fees, no needed upgrades, and no needed add-ons. With each credit you buy, you can use that credit to uncover a lead from your website with Pulse or find information on anyone with Reveal.</p>
        </div>
        <div className="flex gap-3 sm:gap-10 sm:mt-8">
          {sysBillingPlans.map((plan, index) => {
            return <div className="flex flex-col gap-6 py-5 px-4 text-center border border-white-500 rounded-lg" key={index}>
              <h2 className="text-3xl font-bold">{plan.name}</h2>
              <div className="flex items-end justify-center gap-2">
                <p className="text-primary-400 text-4xl font-bold">${plan.priceInCents / 100}</p>
                <p className="text-white-500">/ month</p>
              </div>
              <p className="mx-auto w-9/12 text-sm text-white-500">{plan.description}</p>
              <div className="flex flex-col justify-center gap-4">
                <div className="flex justify-center"><div className="badge sm primary text-sm">
                  <Icon name="check-circle" size={17} className="text-white" />
                  {plan.subtitle}
                </div></div>
                <div className="flex justify-center"><div className="badge sm primary text-sm">
                  <Icon name="check-circle" size={17} className="text-white" />
                  {plan.credits} Reveal Credits
                </div></div>
              </div>
              <p className="mx-auto w-9/12 text-sm text-white-500 italic">Reveal Credits are used for showing full contact info, exporting, and integration push.</p>
              <Button type="secondary" action="button" size="lg" className="w-full" onClick={() => window.location.href = plan.gatewayUrl}>
                <b>BUY CREDITS</b>
              </Button>
            </div>
          })}
        </div>
    </>}
  </Container>
};
export default BuyCreditsModule
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import InputField from "components/atoms/InputField/InputField";
import { useForm } from "react-hook-form";
import Button from "components/atoms/Button/Button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getPersonalInfo, resetUpdateUser, updateUser } from "store/slices/user.slice";
import { toast } from "react-toastify";
import Heading from "components/atoms/layout/Heading/Heading";

const AccountSettings: React.FC<any> = ({ user }) => {
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const {successMessage, errorMessage, isSuccess} = useAppSelector((state) => state.user.updateUser);
  const { register, handleSubmit, watch, formState, setValue, } = useForm({mode: "onBlur",});
  const dispatch = useAppDispatch();
  const watchAllFields = watch()
  const { isValid, dirtyFields, errors } = formState
  const save = (updatedPersonalInfo: any) => {
    window.analytics.identify(user.userId, {
      email: updatedPersonalInfo.email,
      firstName: updatedPersonalInfo.name,
      lastName: updatedPersonalInfo.lastName,
    })
    dispatch(updateUser({
      firstName: updatedPersonalInfo.name,
      lastName: updatedPersonalInfo.lastName,
      newEmail: updatedPersonalInfo.email,
    }));
  };
  useEffect(() => {
    if (!user) return;
    setValue("name", user.firstName);
    setValue("lastName", user.lastName);
    setValue("email", user.email);
  }, [user]);
  useEffect(() => {
    if (isSuccess && successMessage) {
      toast.success(successMessage);
      dispatch(getPersonalInfo());
      dispatch(resetUpdateUser());
    }
    if (errorMessage) {
      toast.error(String(errorMessage));
      dispatch(resetUpdateUser());
    }
  }, [isSuccess, errorMessage, successMessage]);
  useEffect(() => {
    const { email, lastName, name } = watchAllFields
    setDisableButton( email !== user.email || lastName !== user.lastName || name !== user.firstName ? false : true)
  },[formState, dirtyFields, isValid])
  return (
    <div className={`flex flex-col w-full gap-5`}>
      <Heading icon="user" iconSize={37} title="Edit Profile" />
      <form onSubmit={handleSubmit(save)}>
        <div className={styles.container_fields}>
          <div className={styles.container_fields_row}>
            <div className={styles.container_fields_input}>
              <label>First Name</label>
              <InputField
                className={styles.container_fields_row_input}
                type="text"
                name="name"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Name is required",
                  pattern: {
                    value: /^[a-z,A-Z]+$/,
                    message: "Name must be only letters",
                  },
                }}
              />
            </div>
            <div className={styles.container_fields_input}>
              <label>Last Name</label>
              <InputField
                type="text"
                name="lastName"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Last name is required",
                  pattern: {
                    value: /^[a-z,A-Z]+$/,
                    message: "Last name must be only letters",
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.container_fields_input}>
            <label>Email</label>
            <InputField
              iconName="email"
              type="text" name="email"
              errors={errors} register={register}
              validationSchema={{
                required: "Email is required",
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
                  message: "Invalid email address",
              }}}
            />
          </div>
          <div className={styles.container_fields_actions}>
            <Button disabled={disableButton}>Save changes</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountSettings;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { IClientICPState } from "types/icp/clientICP.type"; // Define your IClientICP type accordingly

const baseUrl = process.env.REACT_APP_BASE_URL;

const initialState: IClientICPState = {
  isLoading: false,
  data: {
    threshold: 0,
    icpFilters: [{ name: "", value: "", weight: 0, options: [] }],
  },
  isSuccess: false,
  errorMessage: "",
  createClientICP: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
  },
  updateClientICP: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
  },
  deleteClientICP: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    successMessage: "",
  }
};
export const getClientICP = createAsyncThunk("clientICP/getClientICP", async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}client/icp/get`, {withCredentials: true,});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const createClientICP = createAsyncThunk("clientICP/createClientICP", async (data: { filters: any; threshold: number }, thunkAPI) => {
    try {
      const response = await axios.post(`${baseUrl}client/icp/create`, data, {withCredentials: true,});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const updateClientICP = createAsyncThunk("clientICP/updateClientICP", async (data: { filters?: any; threshold?: number }, thunkAPI) => {
    try {
      const response = await axios.put(`${baseUrl}client/icp/update`, data, {withCredentials: true});
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const deleteClientICP = createAsyncThunk("clientICP/deleteClientICP", async (_, thunkAPI) => {
    try {
      await axios.delete(`${baseUrl}client/icp/delete`, {withCredentials: true});
      return thunkAPI.fulfillWithValue(null);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
}});
export const clientICPSlice = createSlice({
  name: "clientICP",
  initialState,
  reducers: {
    resetCreateClientICP: (state) => {
      state.createClientICP.errorMessage = "";
      state.createClientICP.successMessage = "";
      state.createClientICP.isLoading = false;
      state.createClientICP.isSuccess = false;
    },
    resetUpdateClientICP: (state) => {
      state.updateClientICP.errorMessage = "";
      state.updateClientICP.successMessage = "";
      state.updateClientICP.isLoading = false;
      state.updateClientICP.isSuccess = false;
    },
    resetDeleteClientICP: (state) => {
      state.deleteClientICP.errorMessage = "";
      state.deleteClientICP.isLoading = false;
      state.deleteClientICP.isSuccess = false;
    },
    resetClientICP: (state) => {
      state.errorMessage = "";
      state.data = null;
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientICP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = "";
      state.data = action.payload.data;
    });
    builder.addCase(getClientICP.rejected, (state, action: any) => {
      state.data = null;
      state.isLoading = false;
      state.errorMessage = action.payload?.message || "Something went wrong";
      state.isSuccess = false;
    });
    builder.addCase(getClientICP.pending, (state) => { state.isLoading = true; });
    builder.addCase(createClientICP.fulfilled, (state, action) => {
      state.createClientICP.isLoading = false;
      state.createClientICP.isSuccess = true;
      state.createClientICP.errorMessage = "";
      state.createClientICP.successMessage = action.payload.message;
      state.data = action.payload.data;
    });
    builder.addCase(createClientICP.rejected, (state, action: any) => {
      state.createClientICP.isLoading = false;
      state.createClientICP.successMessage = "";
      state.createClientICP.isSuccess = false;
      state.createClientICP.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder.addCase(createClientICP.pending, (state) => {
      state.createClientICP.isLoading = true;
    });
    builder.addCase(updateClientICP.fulfilled, (state, action) => {
      state.updateClientICP.isLoading = false;
      state.updateClientICP.errorMessage = "";
      state.updateClientICP.isSuccess = true;
      state.updateClientICP.successMessage = action.payload.message;
      state.data = action.payload.data;
    });
    builder.addCase(updateClientICP.rejected, (state, action: any) => {
      state.updateClientICP.isLoading = false;
      state.updateClientICP.successMessage = "";
      state.updateClientICP.isSuccess = false;
      state.updateClientICP.errorMessage =
        action.payload?.message || "Something went wrong";
    });
    builder.addCase(updateClientICP.pending, (state) => {
      state.updateClientICP.isLoading = true;
    });
    builder.addCase(deleteClientICP.fulfilled, (state) => {
      state.deleteClientICP.isLoading = false;
      state.deleteClientICP.isSuccess = true;
      state.deleteClientICP.errorMessage = "";
    });
    builder.addCase(deleteClientICP.rejected, (state, action: any) => {
      state.deleteClientICP.isLoading = false;
      state.deleteClientICP.successMessage = "";
      state.deleteClientICP.isSuccess = false;
      state.deleteClientICP.errorMessage =
        action.payload?.message || "Something went wrong";
    });
  },
});

export const {
  resetCreateClientICP,
  resetUpdateClientICP,
  resetDeleteClientICP,
  resetClientICP,
} = clientICPSlice.actions;

export default clientICPSlice.reducer;
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";
import AccountSettings from "components/molecules/AccountSettings/AccountSettings";
import PasswordChange from "components/molecules/AccountSettings/PasswordChange";
import CompanyPixel from "components/molecules/CompanyPixel/CompanyPixel";
import { Container } from "components";
import { setCurrentPage } from "store/slices/ui.slice";

const MyAccount: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isSuccess, data } = useAppSelector((state) => state.user);
  const isLoading = useAppSelector((state) => state.user.updateUser.isLoading);
  const { setValue } = useForm({mode: "onBlur" });
  useEffect(() => {
    window.analytics.page("Account Settings")
    dispatch(setCurrentPage("account"));
  }, []);
  useEffect(() => {
    if (!isSuccess) return;
    setValue("name", data.firstName);
    setValue("email", data.email);
    setValue("lastName", data.lastName);
  }, [isSuccess]);
  if (isLoading) return <div style={{ position: "absolute", width: "100%", height: "100vh" }}><Loading /></div>
  return (
    <Container>
      <div className="flex flex-col items-center">
        <div className="flex flex-col w-full sm:w-3/6 gap-6 sm:gap-12">
          <AccountSettings user={data} />
          <PasswordChange />
          <CompanyPixel />
        </div>
      </div>
    </Container>
  )
};
export default MyAccount;

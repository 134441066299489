import { FC, useState } from "react";
import { useAppSelector } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";
import { ClientAnalyticsNodesDTO, ClientAnalyticsRowDTO } from "store/slices/client.slice";
import Icon from "components/atoms/Icon/Icon";
import Tooltip from "components/atoms/Tooltip/MovingTooltip";
import { Link } from "react-router-dom";
const AnalyticsBody: FC = () => {
  const {isLoading: analyticsLoading, isSuccess: analyticsIsSuccess, data: analyticsData} = useAppSelector(state => state.client.analytics);
  // ⭐ Diagram Params
  const qtySteps = analyticsData?.steps || 0;
  const spaceXBetweenNodes = 240;
  const spaceYBetweenNodesAfterFirstStep = 45;
  const maxHeightPerRow = 250
  const nodeWidth = 20
  const widthContent = qtySteps * spaceXBetweenNodes
  const [hoveredNode, setHoveredNode] = useState<string | any | number>('');
  const [highlightedNodes, setHighlightedNodes] = useState<string[] | any>([]);
  const [isStep, setStep] = useState<number>(0);
  const handleMouseEnter = (nodeId: string | number, nodeStep: number) => {
    setHoveredNode(nodeId);
    setStep(nodeStep);
    const findParentNodes = (nodeId: string | number, parents: string[] = []): string[] => {
      const parentLinks = analyticsData?.rows
        .flatMap((row) => row.links)
        .filter((link:{source: string | number, target: string | number}) => link.target === nodeId) || [];
      const parentNodes:number[] = parentLinks.map((link) => link.source);
      if (parentNodes.length > 0) {
        return parentNodes.reduce((acc:any, parentNodeId:any) => {
          if (!acc.includes(parentNodeId)) {
            acc.push(parentNodeId);
            acc.push(...findParentNodes(parentNodeId, acc));
          }
          return acc;
        }, []);
      }
      return parents; 
    };
    const parentNodes = findParentNodes(nodeId);
    setHighlightedNodes([nodeId, ...parentNodes]);
  };
  const handleMouseLeave = () => {
    setHoveredNode('');
    setHighlightedNodes([]);
    setStep(0);
  };
  const calculateNodeAndStrokeHeight = (visits: number, step: number) => {
    let nodeHeight = visits / 7;
    if (step === 1) {
      if (nodeHeight > maxHeightPerRow) nodeHeight = maxHeightPerRow
    } else {
      if (nodeHeight > spaceYBetweenNodesAfterFirstStep) nodeHeight = spaceYBetweenNodesAfterFirstStep - 8;
    }
    if (nodeHeight < 5) nodeHeight = 5
    return Math.round(nodeHeight);
  }
  const getXYPosition = (dto: ClientAnalyticsNodesDTO, indexInStep: number) => {
    const xPosition = (dto.step - 1) * spaceXBetweenNodes;
    const yPosition = indexInStep * spaceYBetweenNodesAfterFirstStep
    return { x: xPosition, y: yPosition }
  }
  const getRowHeight = (row: ClientAnalyticsRowDTO) => {
    const stepCounts = row.nodes.reduce<Record<number, number>>((acc, obj) => {
      acc[obj.step] = (acc[obj.step] || 0) + 1;
      return acc;
    }, {});
    const maxStep = Object.entries(stepCounts).reduce(
      (max, [step, count]) => {
        const stepNumber = parseInt(step, 10);
        return count > max.count ? { step: stepNumber, count } : max;
      },
      { step: 0, count: 0 }
    );
    return maxStep.count * spaceYBetweenNodesAfterFirstStep
  }
  if (analyticsLoading) return <Loading height="100%" />
  if (analyticsData?.rows.length === 0 || !analyticsIsSuccess || !analyticsData) return <div className="w-full h-full flex flex-col gap-3 items-center justify-center">
    <Icon name="analytics" className="text-white-500" size={50} />
    <p className='text-gray-400'><b className='text-white-500 mr-1'>{analyticsData?.rows.length || 0}</b>{' '}analytics found</p>
  </div>
  return <div className="px-4 pb-2 flex flex-col gap-4">
    {qtySteps > 0 && <header style={{ width: `${widthContent}px`}}><table><thead><tr>
      {Array.from({ length: qtySteps }, (_, i) => <th key={"step-"+i} style={{transition: 'all 0.3s ease-in-out'}} className={`!px-0 !pb-0 !border-none text-left ${ isStep - 1 >= i ? '!text-white-500 scale-105' : 'text-gray-400 scale-95'}`}>{i === 0 ? `Starting Point` : `Step +${i}`}</th>)}
    </tr></thead></table></header>}
    {analyticsData.rows.map((row, indexRow) => {
      const rowHeight = getRowHeight(row)
      return <div key={"row-"+indexRow} className="relative w-full" style={{ height: rowHeight }}>
        {row.nodes.map((node, indexNode) => {
          const visits = row?.links.find(link => link.target === node.id)?.value || node.visits || 0;
          const nodeHeight = calculateNodeAndStrokeHeight(visits, node.step)
          const getIndexInSameStep = row.nodes.filter(n => n.step === node.step).findIndex(n => n.id === node.id);
          const pos = getXYPosition(node, getIndexInSameStep)
          return <div key={indexRow + "node-" + indexNode}   onMouseLeave={handleMouseLeave}
            onMouseEnter={() => handleMouseEnter(node.id, node.step)} className="absolute" style={{ top: pos.y, left: pos.x, width: spaceXBetweenNodes }}><div className={`flex gap-3 z-10 relative`}>
            {/* <Link to={`/analytics/view?path_id=${node.id}`}> */}
              <div className={`bg-primary-500 cursor-pointer rounded-md border ${highlightedNodes.includes(node.id) ? "border-primary-300" : "border-transparent"}`}
                style={{ height: nodeHeight, width: nodeWidth, transition: "background-color 0.3s, border 0.3s" }} />
            {/* </Link> */}
            <Tooltip // followCursor
              tooltipStyles={{ width: 300, padding: "7px" }}
              content={<>
               <div className="font-bold text-paper-500 text-[15px] truncate mb-1">{node.name}</div>
               <div className="flex gap-3">
                  {/* <div className="flex items-center gap-1 cursor-pointer hover:text-primary-500 hover:underline text-paper-400">
                    <Icon name="people" color="var(--color-primary-500)" />
                    <div className="text-[12px]">Leads</div>
                  </div> */}
                  <div className="flex items-center gap-1">
                    <Icon name="url" color="var(--color-primary-500)" />
                    <div className="text-paper-400 text-[12px]">{visits} Visits</div>
                  </div>
                </div>
              </>}>
              <div className="relative flex-1 cursor-pointer overflow-hidden">
                <h2 className={`transition-transform text-sm font-bold truncate -mt-1 w-full max-w-[150px] whitespace-nowrap ${highlightedNodes.includes(node.id) ? "scale-105" : ""}`}>{node.name}</h2>
                <h3 className="text-[12px] ">{visits}</h3>
              </div>
            </Tooltip>
          </div></div>
        })}
        <svg className="absolute inset-0 h-full pointer-events-none" style={{ width: widthContent }}>
          {row.links.map((link, indexLink) => {
            const sourceNode = row.nodes.find(node => node.id === link.source);
            const targetNode = row.nodes.find(node => node.id === link.target);
            if (!sourceNode || !targetNode) return
            const sourcePos = getXYPosition(sourceNode, row.nodes.filter(n => n.step === sourceNode.step).findIndex(n => n.id === sourceNode.id));
            const targetPos = getXYPosition(targetNode, row.nodes.filter(n => n.step === targetNode.step).findIndex(n => n.id === targetNode.id));
            const strokeWidth = calculateNodeAndStrokeHeight(link.value, targetNode.step);
            const isFirstLineInStep = targetPos.y === 0;
            const marginForFirstRow = isFirstLineInStep ? strokeWidth < 24 ? strokeWidth > 10 ? 9 : 3 : 20 : 0;
            const adjustmentMargin1 = isFirstLineInStep ? 0 : 3;
            const path = `
              M${sourcePos.x + nodeWidth + 5},${sourcePos.y + marginForFirstRow + adjustmentMargin1}
              C${sourcePos.x + 150},${sourcePos.y + marginForFirstRow + adjustmentMargin1}
              ${targetPos.x - 60},${targetPos.y + marginForFirstRow + adjustmentMargin1}
              ${targetPos.x - 5},${targetPos.y + marginForFirstRow + adjustmentMargin1}
            `;
            return <path
              key={indexRow + "path-" + indexLink} d={path}
              className={`fill-none stroke-primary-500 ${highlightedNodes.includes(link.target) ? "opacity-60 z-50" : "opacity-20"}`}
              strokeWidth={strokeWidth}
              style={{ transition: "stroke 0.4s, opacity 0.3s" }}
            />
          })}
        </svg>
      </div>
    })}
  </div>
};
export default AnalyticsBody
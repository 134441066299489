import React, { useState } from "react";
import styles from "./style.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Profile from "../Profile/Profile";
import theme from "../../../assets/themes";
import Icon from "components/atoms/Icon/Icon";
import UserController from "controllers/user";
import constants from "utils/constants";

const BurgerMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { data } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toggleMenu = () => setOpen(!open)
  const location = useLocation();
  const { data: client } = useAppSelector((state) => state.client);
  const handleLogout = async () => {
    await UserController.Auth.logout({data, dispatch})
    setOpen(false)
    navigate("/signin")
  };
  return (
    <div className={styles.burger}>
      <button className={`text-white-500`} onClick={toggleMenu}><Icon name="menu" size={25}/></button>
      <div className={`${styles.burger_menu} ${open ? styles.open : styles.close}`}>
        <div className={`${styles.burger_menu_items} flex flex-col text-white-500 gap-10`}>
          <Link to="/" onClick={()=>setOpen(false)}><img src={theme["logo"]} alt="logo" className={styles.header_logo} /></Link>
          <Profile name={{ name: data.firstName, lastName: data.lastName }} dropDown={false} credits={data.creditBalance} />
          {/* {!(data.hasUnlimitedCredits || data.isInvitedUser) && (
            <Button iconName="credits"
              onClick={async () => {
                if (!data.gatewayCustomerId) {
                  navigate("/user/billing")
                  setTimeout(() => { toast.warn("Please add a payment method to your account before buying credits.") }, 900);
                  return
                }
                const billingData = await dispatch( getBillingPortalUrl() )
                const url = billingData.payload.data;
                if (!url) {
                  toast.error("Something went wrong. Please try again later.")
                  return
                }
                window.open(url, "_blank")
                // navigate("/buyCredits", { state: { fromBurgerMenu: true } });
                // setOpen(false);
              }}
            >Buy Credits</Button>
          )} */}
          <div className="flex flex-col gap-7">
            <Link to={`/${constants.PIXEL_KEYWORD}`} className={[`/${constants.PIXEL_KEYWORD}`].includes(location.pathname) ? `text-primary-500` : ""} onClick={()=>setOpen(false)}>
              <div className="flex items-center gap-2 capitalize">
                <Icon name="pulse" />
                {constants.PIXEL_KEYWORD}
            </div></Link>
            <Link to={`/${constants.LEADSEARCH_KEYWORD}`} className={[`/${constants.LEADSEARCH_KEYWORD}`].includes(location.pathname) ? `text-primary-500` : ""} onClick={()=>setOpen(false)}>
              <div className="flex items-center gap-2 capitalize">
                <Icon name="search" />
                {constants.LEADSEARCH_KEYWORD}
            </div></Link>
            <Link to={`/user/myAccount`} className={[`/user/myAccount`].includes(location.pathname) ? `text-primary-500` : ""} onClick={()=>setOpen(false)}>
              <div className="flex items-center gap-2 capitalize">
                <Icon name="user-circle" />
                Account
            </div></Link>
            <Link to={`/scoring`} className={[`/scoring`].includes(location.pathname) ? `text-primary-500` : ""} onClick={()=>setOpen(false)}>
              <div className="flex items-center gap-2 capitalize">
                <Icon name="score" />
                <p className={[`/scoring`].includes(location.pathname) ? `text-primary-500` : ""}>
                Lead Scoring
                </p>
              
            </div></Link>
            <Link to={`/integrations`} className={[`/integrations`].includes(location.pathname) ? `text-primary-500` : ""} onClick={()=>setOpen(false)}>
              <div className="flex items-center gap-2 capitalize">
                <Icon name="layers" />
                Integrations
            </div></Link>
            {/* <Link to={`/user/billing`} className={[`/user/billing`].includes(location.pathname) ? `text-primary-500` : ""} 
              onClick={async ()=> {
                if (!data.gatewayCustomerId) {
                  navigate("/user/billing")
                  setTimeout(() => { toast.warn("Please add a payment method to your account before buying credits.") }, 900);
                  return
                }
                const billingData = await dispatch( getBillingPortalUrl() )
                const url = billingData.payload.data;
                if (!url) {
                  toast.error("Something went wrong. Please try again later.")
                  return
                }
                window.open(url, "_blank")
                // setOpen(false)
              }}>
              <div className="flex items-center gap-2 capitalize">
                <Icon name="card" />
                Billing & Payment
            </div></Link> */}
            {!data.isInvitedUser && 
              <Link to={`/company`} className={[`/company`].includes(location.pathname) ? `text-primary-500` : ""} onClick={()=>setOpen(false)}>
                <div className="flex items-center gap-2 capitalize">
                  <Icon name="people" />
                  Company Team
              </div></Link>}
            <div className="flex items-center gap-2 capitalize" onClick={handleLogout}><Icon name="logout" /> Log Out</div>
          </div>
        </div>
        <button className={`${styles.burger_menu_closeBtn} text-white-500`} onClick={toggleMenu}><Icon name="close" size={23} /></button>
      </div>
    </div>
  );
};

export default BurgerMenu;

import React, { useState, useRef } from "react";
import { default as TooltipUI } from "@mui/material/Tooltip";

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactElement;
  isFullWidth?: boolean;
  tooltipStyles?: object; // Custom styles for the tooltip
  followCursor?: boolean; // Enable cursor-following
}
const MovingTooltip: React.FC<TooltipProps> = ({
  content,
  isFullWidth,
  children,
  tooltipStyles,
  followCursor = false,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const divRef = useRef<HTMLDivElement>(null);
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const offsetX = event.clientX - rect.left; // X position relative to the div
      const offsetY = event.clientY - rect.top; // Y position relative to the div
      setPosition({ top: offsetY, left: offsetX });
    }
  };
  return (
    <div
      ref={divRef}
      onMouseMove={followCursor ? handleMouseMove : undefined}
      style={{ position: "relative", width: isFullWidth ? "100%" : "fit-content" }}
    >
      <TooltipUI
        title={content}
        placement="top"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              textAlign: "left",
              fontSize: 14,
              fontFamily: "Inter, sans-serif",
              padding: "8px",
              borderRadius: "8px",
              bgcolor: 'var(--color-white-500)',
              color: "var(--color-white-500)",
              border: "1px solid var(--color-white-500)",
              position: followCursor ? "absolute" : "relative",
              top: followCursor ? `${position.top}px` : undefined, // Tooltip relative top
              left: followCursor ? `${position.left}px` : undefined, // Tooltip relative left
              transform: followCursor ? "translate(-50%, -100%)" : undefined, // Adjust alignment
              transition: "opacity 1.5s ease, transform 1.5s ease",
              // opacity: 0,
              // transform: "scale(0.9)",
              ...tooltipStyles, // Parent custom styles
            },
          },
          arrow: {
            sx: {
              color: 'var(--color-white-500)',
            },
          }
        }}
      >
        {children}
      </TooltipUI>
    </div>
  );
};

export default MovingTooltip;

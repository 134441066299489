import {ClientICPFilterOperatorENUM} from "../components/modules/SearchMain/SearchMainHelper";
import {Dispatch, SetStateAction} from "react";

interface FilterOption {
    label: string;
    value: string | number;
}

export type SetStateFunction = Dispatch<SetStateAction<any>> | ((value: FilterOption[] | string) => void);

export const filterHandlerWithGeneralOptions = (setState: SetStateFunction) => (
    value:  FilterOption[] | string
) => {
    let updatedValue;

    if (typeof value !== "string" && value?.length > 1) {
        const isValueHasIsKnownOption = value.some(el => el.value === ClientICPFilterOperatorENUM.IS_REQUIRED);
        updatedValue = isValueHasIsKnownOption
            ? value[0].value === ClientICPFilterOperatorENUM.IS_REQUIRED
                ? value.filter(el => el.value !== ClientICPFilterOperatorENUM.IS_REQUIRED) // Remove "IS_REQUIRED" if first
                : [{ label: 'Is Known', value: ClientICPFilterOperatorENUM.IS_REQUIRED }] // Set only "IS_REQUIRED" if not first
            : value;
    } else {
        updatedValue = value;
    }

    setState(updatedValue);
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Container from "components/atoms/layout/Container/Container";
import PulseBody from "./PulseBody";
import { CopyBlock, a11yDark as themeBlock } from "react-code-blocks";
import "./../SearchMain/index.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import constants from "utils/constants";
import Icon from "components/atoms/Icon/Icon";
import PixelHelper from "helpers/PixelHelper";
import { toast } from "react-toastify";
import { RootState } from "store/store";
import Loading from "components/atoms/Loading/Loading";
import { getCompanyPixel } from "store/slices/company.slice";
import { getAllPixelLeads, getTotalPixelLeads } from "store/slices/insights";
import { setCurrentPage } from "store/slices/ui.slice";
import { parseJSON } from "../../../utils/parseJson";
import { useSearchParams } from "react-router-dom";
import SearchMainFilters from "../SearchMain/SearchMainFilters";
import { buildQueryString } from "../../../utils/buildQueryString";
import { filterHandlerWithGeneralOptions } from "../../../utils/filterHandlerWithGeneralOptions";
import EmailVerification from "components/molecules/EmailVerification/EmailVerification";

const CONTENT_CLASS = `bg-gray-900 rounded-lg flex flex-col h-[76vh]`;
const PulseModule: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: user, isSuccess: userIsSuccess } = useAppSelector((state) => state.user);
  const clientTrackingPixel = useAppSelector((state: RootState) => state.company.companyPixel);
  const { data: leads, isSuccess: pixelIsSuccess, } = useAppSelector((state) => state.insights.leads);
  const [isLoadingValidationModule, setIsLoadingValidationModule] =
    useState(true);
  const [isPulseDataEmpty, setIsPulseDataEmpty] = useState(true);
  const [isSearching] = useState(false);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  // ⭐ Filters
  const [filterJobTitle, setFilterJobTitle] = useState<any[]>([]);
  const [filterRevealed, setFilterRevealed] = useState<{
    label: string;
    value: boolean;
  } | null>(null);
  const [filterSeniority, setFilterSeniority] = useState<any[]>([]);
  const [filterDepartment, setFilterDepartment] = useState<any[]>([]);
  const [filterIndustry, setFilterIndustry] = useState<any[]>([]);
  const [filterCompany, setFilterCompany] = useState<any[]>([]);
  const [filterCompanyHeadcount, setFilterCompanyHeadcount] = useState<any[]>(
    []
  );
  const [filterCompanyRevenue, setFilterCompanyRevenue] = useState<any[]>([]);
  const [filterLocationCompany, setFilterLocationCompany] = useState<any[]>([]);
  const [filterLocationPerson, setFilterLocationPerson] = useState<any[]>([]);
  const [filterGender, setFilterGender] = useState<any[]>([]);
  const [filterIncomeRange, setFilterIncomeRange] = useState<any[]>([]);
  const [pageCurrent, setPageCurrent] = useState<number>(
    () => Number(searchParams.get("current_page")) || 1
  );

  // ⭐ Search Inputs
  const [searchPeople, setSearchPeople] = useState<string | number>("");
  const [searchLinkedin, setSearchLinkedin] = useState<any>("");
  const [searchPageVisited, setSearchPageVisited] = useState("");
  const [searchNumberPagesViewed, setSearchNumberPagesViewed] = useState("");
  const [searchNumberOfVisits, setSearchNumberOfVisits] = useState("");
  const [searchTimeOnPage, setSearchTimeOnPage] = useState("");
  const [searchLeadScore, setSearchLeadScore] = useState("");

  useEffect(() => {
    // @ts-ignore
    const params = Object.fromEntries([...searchParams]);
    if (params.only_leads_revealed)
      setFilterRevealed({
        label: "Only revealed contacts",
        value: params.only_leads_revealed === "true",
      });
    if (params.name) setSearchPeople(params.name);
    if (params.linkedin) setSearchLinkedin(params.linkedin);
    if (params.person_location)
      setFilterLocationPerson(parseJSON(params.person_location));
    if (params.gender) setFilterGender(parseJSON(params.gender));
    if (params.job_title) setFilterJobTitle(parseJSON(params.job_title));
    if (params.seniority) setFilterSeniority(parseJSON(params.seniority));
    if (params.department) setFilterDepartment(parseJSON(params.department));
    if (params.industry) setFilterIndustry(parseJSON(params.industry));
    if (params.company_name) setFilterCompany(parseJSON(params.company_name));
    if (params.company_location)
      setFilterLocationCompany(parseJSON(params.company_location));
    if (params.company_headcount)
      setFilterCompanyHeadcount(parseJSON(params.company_headcount));
    if (params.company_revenue)
      setFilterCompanyRevenue(parseJSON(params.company_revenue));
    if (params.income_range)
      setFilterIncomeRange(parseJSON(params.income_range));
    if (params.page_visited) setSearchPageVisited(params.page_visited);
    if (params.number_pages_viewed)
      setSearchNumberPagesViewed(params.number_pages_viewed);
    if (params.number_of_visits)
      setSearchNumberOfVisits(params.number_of_visits);
    if (params.time_on_page) setSearchTimeOnPage(params.time_on_page);
    if (params.lead_score) setSearchLeadScore(params.lead_score);
  }, [searchParams]);
  const pageSize = 25;
  const onlyLeadsRevealedFromUrl = searchParams.get("only_leads_revealed");
  const personLocationFromUrl = searchParams.get("person_location");
  const genderFromUrl = searchParams.get("gender");
  const jobTitleFromUrl = searchParams.get("job_title");
  const seniorityFromUrl = searchParams.get("seniority");
  const departmentFromUrl = searchParams.get("department");
  const industryFromUrl = searchParams.get("industry");
  const companyNameFromUrl = searchParams.get("company_name");
  const companyLocationFromUrl = searchParams.get("company_location");
  const companyHeadcountFromUrl = searchParams.get("company_headcount");
  const nameFromUrl = searchParams.get("name");
  const linkedinFromUrl = searchParams.get("linkedin");
  const pageVisitedFromUrl = searchParams.get("page_visited");
  const numberPagesViewedFromUrl = searchParams.get("number_pages_viewed");
  const numberOfVisitsFromUrl = searchParams.get("number_of_visits");
  const timeOnPageFromUrl = searchParams.get("time_on_page");
  const leadScoreFromUrl = searchParams.get("lead_score");
  const pageFromUrl = searchParams.get("current_page");
  const orderColumn = searchParams.get("order_column");
  const orderMethod = searchParams.get("order_method");

  const queryParams = useCallback(() => {
    // const page = searchParams.get("current_page") || pageCurrent;
    // const allEmpty = filterDeps.every(dep => dep === "" || (Array.isArray(dep) && dep.length === 0));
    // if (allEmpty) return { page };
    return {
      page: pageFromUrl,
      size: pageSize,
      search_name: nameFromUrl || "",
      search_linkedin: linkedinFromUrl || "",
      page_visited: pageVisitedFromUrl || "",
      number_pages_viewed: numberPagesViewedFromUrl || "",
      number_of_visits: numberOfVisitsFromUrl || "",
      time_on_page: timeOnPageFromUrl || "",
      lead_score: leadScoreFromUrl || "",
      income_ranges:
        filterIncomeRange.length > 0
          ? JSON.stringify(filterIncomeRange.map((i) => i.value))
          : ``,
      only_leads_revealed: onlyLeadsRevealedFromUrl || "",
      job_titles: buildQueryString(jobTitleFromUrl),
      companies: buildQueryString(companyNameFromUrl),
      company_headcounts: buildQueryString(companyHeadcountFromUrl),
      industries: buildQueryString(industryFromUrl),
      genders: buildQueryString(genderFromUrl),
      seniorities: buildQueryString(seniorityFromUrl),
      departments: buildQueryString(departmentFromUrl),
      locations_person: buildQueryString(personLocationFromUrl),
      locations_company: buildQueryString(companyLocationFromUrl),
      ...(orderColumn && orderMethod
        ? { order_column: orderColumn, order_method: orderMethod }
        : {}),
    };
  }, [
    nameFromUrl,
    linkedinFromUrl,
    jobTitleFromUrl,
    companyNameFromUrl,
    industryFromUrl,
    genderFromUrl,
    seniorityFromUrl,
    departmentFromUrl,
    personLocationFromUrl,
    companyLocationFromUrl,
    companyHeadcountFromUrl,
    pageSize,
    pageFromUrl,
    pageVisitedFromUrl,
    numberPagesViewedFromUrl,
    numberOfVisitsFromUrl,
    timeOnPageFromUrl,
    leadScoreFromUrl,
    onlyLeadsRevealedFromUrl,
    orderColumn,
    orderMethod,
  ]);
  const fetchResults = useCallback(async () => {
    const params = queryParams();
    dispatch(getTotalPixelLeads(params));
    dispatch(getAllPixelLeads(params));
  }, [queryParams]);
  useEffect(() => {
    fetchResults();
  }, [fetchResults]);
  useEffect(() => {
    dispatch(getCompanyPixel());
    window.analytics.page("Pulse");
    dispatch(setCurrentPage(constants.PIXEL_KEYWORD));
  }, []);
  useEffect(() => {
    if (!pixelIsSuccess) return;
    if (leads.length > 0) setIsPulseDataEmpty(false);
  }, [pixelIsSuccess, leads]); // eslint-disable-line
  useEffect(() => {
    if (!userIsSuccess || !pixelIsSuccess) return;
    setIsLoadingValidationModule(false);
  }, [userIsSuccess, pixelIsSuccess]); // eslint-disable-line
  useEffect(() => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("current_page", String(pageCurrent));
      return newParams;
    });
  }, [pageCurrent]);
  return (
    <Container>
      <div className='flex sm:gap-6'><div className='flex basis-1/4'><div className='items-center flex gap-3 text-white-500'>
        <Icon name='pulse' size={30} />
        <h1 className='font-bold capitalize text-2xl'>{constants.PIXEL_KEYWORD}</h1>
      </div></div></div>
      <div className='flex sm:gap-6'>
        <div className={`${CONTENT_CLASS} basis-1/5 gap-3 sm:flex hidden`}>
          <SearchMainFilters
            isGroupedOptions={true}
            pageCurrent={pageCurrent}
            setPageCurrent={setPageCurrent}
            isAppliedFilter={isAppliedFilter}
            setIsAppliedFilter={setIsAppliedFilter}
            isSearching={isSearching}
            fetchResults={fetchResults}
            searchPeople={searchPeople}
            setSearchPeople={setSearchPeople}
            searchLinkedin={searchLinkedin}
            setSearchLinkedin={setSearchLinkedin}
            filterRevealed={filterRevealed}
            setFilterRevelead={setFilterRevealed}
            setFilterJobTitle={filterHandlerWithGeneralOptions(
              setFilterJobTitle
            )}
            filterJobTitle={filterJobTitle}
            filterCompany={filterCompany}
            setFilterCompany={filterHandlerWithGeneralOptions(setFilterCompany)}
            filterIndustry={filterIndustry}
            setFilterIndustry={setFilterIndustry}
            filterLocationPerson={filterLocationPerson}
            setFilterLocationPerson={setFilterLocationPerson}
            filterLocationCompany={filterLocationCompany}
            setFilterLocationCompany={setFilterLocationCompany}
            // UNCOMMENT TO ENABLE NEXT FILTERS =>
            // filterDepartment={filterDepartment} setFilterDepartment={setFilterDepartment}
            // filterCompanyHeadcount={filterCompanyHeadcount} setFilterCompanyHeadcount={setFilterCompanyHeadcount}
            // filterSeniority={filterSeniority} setFilterSeniority={setFilterSeniority}
            // searchPageVisited={searchPageVisited} setSearchPageVisited={setSearchPageVisited}
            // searchNumberPagesViewed={searchNumberPagesViewed} setSearchNumberPagesViewed={setSearchNumberPagesViewed}
            // searchNumberOfVisits={searchNumberOfVisits} setSearchNumberOfVisits={setSearchNumberOfVisits}
            // searchTimeOnPage={searchTimeOnPage} setSearchTimeOnPage={setSearchTimeOnPage}
            // searchLeadScore={searchLeadScore} setSearchLeadScore={setSearchLeadScore}
          />
        </div>
        <div
          className={`SearchMainBody ${CONTENT_CLASS} w-full sm:w-auto sm:basis-4/5 overflow-x-auto`}
        >
          {/* ⭐ Verifications to ensure the user has access to the Pulse module */}
          {isLoadingValidationModule ? (
            <Loading />
          ) : !user.clientId ? (
            <div className="text-white-500 text-center flex flex-col gap-3 justify-center items-center p-4 py-12 sm:py-24 w-2/3 m-auto">
              <Icon name="sad" size={70} />
              <h1 className="text-xl mt-4">
                You have no{" "}
                <span className="capitalize">{constants.PIXEL_KEYWORD}</span>
              </h1>
              <p className="text-gray-400 text-base">
                Your current subscription status restricts access to{" "}
                <span className="capitalize">{constants.PIXEL_KEYWORD}</span>.
              </p>
              <p className="text-gray-400 mb-5 text-base">
                Please contact our team by the email{" "}
                <a
                  className="link"
                  href={`mailto:${constants.COMPANY_1_EMAIL_SUPPORT}`}
                >
                  {constants.COMPANY_1_EMAIL_SUPPORT}
                </a>
                . We are here to help you enjoy the full range of services we
                offer.
              </p>
              <p className="text-gray-400 text-base">
                De-anonymize the visitors on your website and receive data
                within hours after placing our tracking pixel.
              </p>
              <p className="pt-3 text-white-500 text-base font-bold">{`Copy and paste this code into the <HEAD> of every webpage you want to track:`}</p>
            </div>
          ) : !user.hasVerifiedEmail ? (
            <EmailVerification />
          ) : isPulseDataEmpty ? (
            <div className="flex flex-col items-center justify-center gap-4 w-2/3 h-full m-auto">
              <div className="bg-primary-500 flex justify-center items-center rounded-lg text-white-500 w-[58px] h-[52px]">
                <Icon size={37} name="code" />
              </div>
              <h1 className="font-bold text-xl">
                Start by setting up a tracking pixel
              </h1>
              <div className="space-y-3">
                <p className="text-gray-400 text-base">
                  Turn anonymous clicks and page views into tangible leads by
                  identifying individuals and companies behind the web visits.
                </p>
                <p className="text-gray-400 text-base">
                  While some traffic will ultimately stay anonymous, you will
                  find that much of your traffic can be deanonymized to give you
                  valuable insights that translate into strategic marketing
                  decisions.
                </p>
                <p className="text-gray-400 text-base">
                  De-anonymize the visitors on your website and receive data
                  within hours after placing our tracking pixel.
                </p>
                <p className="pt-3 text-white-500 text-base font-bold">{`Copy and paste this code into the <HEAD> of every webpage you want to track:`}</p>
              </div>
              <CopyBlock
                theme={themeBlock}
                language="html"
                showLineNumbers={false}
                wrapLongLines
                customStyle={{
                  padding: "8px 10px",
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
                onCopy={() =>
                  toast.success(
                    "Your tracking pixel has been successfully copied to the clipboard"
                  )
                }
                text={PixelHelper.generateScript(clientTrackingPixel)}
              />
            </div>
          ) : (
            <PulseBody
              isAppliedFilter={isAppliedFilter}
              setIsAppliedFilter={setIsAppliedFilter}
              query={JSON.stringify(queryParams())}
              searchPeople={searchPeople}
              setSearchPeople={setSearchPeople}
              searchLinkedin={searchLinkedin}
              filterRevealed={filterRevealed}
              filterJobTitle={filterJobTitle}
              filterCompany={filterCompany}
              filterCompanyHeadcount={filterCompanyHeadcount}
              filterCompanyRevenue={filterCompanyRevenue}
              filterIndustry={filterIndustry}
              filterLocationPerson={filterLocationPerson}
              filterLocationCompany={filterLocationCompany}
              filterGender={filterGender}
              filterSeniority={filterSeniority}
              filterDepartment={filterDepartment}
              filterIncomeRange={filterIncomeRange}
              pageCurrent={pageCurrent}
              setPageCurrent={setPageCurrent}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
export default PulseModule;

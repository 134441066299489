import React, { CSSProperties } from "react";
import {default as PopoverUI} from '@mui/material/Popover';
import theme from "../../../assets/themes"
const Popover: React.FC<{
    anchor: null | HTMLElement;
    setAnchor: any;
    isOpen: boolean,
    children?: React.ReactNode
}> = ({ isOpen, anchor, setAnchor, children }) => {
    return <PopoverUI
        open={isOpen}
        anchorEl={anchor}
        onClose={()=> setAnchor(null)}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }} 
        closeAfterTransition
        slotProps={{
            paper: {
                style: {
                    background: theme["--dropDown-bg"],
                    color: theme["--text-color-W"],
                    border: `1px solid ${theme["--border"]}`,
                    padding: 15,
                    marginTop: 10,
                    minWidth: 100,
                    borderRadius: 10,
                    WebkitBoxShadow: "4px 6px 5px 0px rgba(0,0,0,0.2)",
                    boxShadow: "4px 6px 5px 0px rgba(0,0,0,0.2)",
                },
            },
        }}
    >
        <div style={theme as CSSProperties}>
            {children}
        </div>
    </PopoverUI>
};
export default Popover
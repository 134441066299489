import React, { Dispatch, SetStateAction, useEffect } from "react";
import { flexRender, Row } from "@tanstack/react-table";
import { getEmailBest, getPhoneBest } from "utils/workWithData";
import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Loading from "components/atoms/Loading/Loading";
import { toast } from "react-toastify";
import { getPersonalInfo } from "store/slices/user.slice";
import EntryFormatter from "utils/EntryFormatter";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { messages } from "utils/message";
import LeadHelper from "helpers/LeadHelper";
const SearchMainRow: React.FC<{
  row: Row<LeadProfileDTO>; isSelected: boolean; setSelectedRows: Dispatch<SetStateAction<string[]>>;
}> = ({ row, isSelected = false, setSelectedRows }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading: CRIsLoading, isSuccess: CRIsSuccess, data: CRData } = useAppSelector((state) => state.data.contactsRevealed.find((x) => x.id === row.original.leadId)) ?? {};
  const handleCheckboxChange = (rowId: string) => {
    setSelectedRows((prev) => {
      if (prev.includes(rowId)) return prev.filter((id) => id !== rowId);
      else return [...prev, rowId];
    })
  };
  const displayData = (cell: any) => {
    if (cell.column.id === "email" && CRIsLoading) return <Loading height="auto" spinnerSize={32} />;
    if (CRIsSuccess && CRData) {
      if (cell.column.id === "name" && CRData.nameFirst) {
        return (
          CRData.nameFirst +
          (CRData.nameLast !== CRData.nameFirst ? " " +(CRData.nameLast || "") : "")
        );
      } else if (cell.column.id === "email") {
        const topEmail = getEmailBest(CRData);
        if (topEmail && topEmail !== "-") return <div className="badge sm green" title={topEmail}>{topEmail}</div>
        return null;
      } else if (cell.column.id === "phone") {
        const topPhone = getPhoneBest(CRData);
        if (topPhone && topPhone !== "-") return <div className="badge sm green" title={topPhone}>{topPhone}</div>
        return null;
      } else if (cell.column.id === "jobTitle") {
        return EntryFormatter.formatJobTitle(CRData.linkedInTitle);
      } else if (cell.column.id === "companyName")
        return CRData.organization?.name ?? "-";
    }
    return flexRender(cell.column.columnDef.cell, cell.getContext());
  };
  const searchParams = window.location.href?.split("?")?.[1];
  // ⭐ Update credits after successful contact reveal
  useEffect(() => {
    if (!CRIsSuccess) return;
    if (CRData) {
      setTimeout(() => { dispatch(getPersonalInfo()) }, 10000)
      if (LeadHelper.hasContactData(CRData)) toast.success(messages.ENRICHMENT_CONTACTREVEAL_SUCCESS, {toastId: "contactRevealed"});
      else toast.error(messages.ENRICHMENT_CONTACTREVEAL_CONTACT_MISSING);
    } else {
      toast.error(messages.ENRICHMENT_CONTACTREVEAL_FAILED);
    }
  }, [CRIsSuccess]); // eslint-disable-line
  return <tr>
    {row.getVisibleCells().map((cell) => {
      const value = cell.getValue() as string;
      const isContactHiddenColumn = cell.column.id === "email" && row.original.hideContactInfo && !CRData;
      if (cell.column.id === "phone" && row.original.hideContactInfo && !CRData) return null;
      return <td key={cell.id}
        onClick={() => {navigate(`/lead/${row.original.leadId}${searchParams?.length > 0 ? `?${searchParams}` : ""}`);}}
        colSpan={isContactHiddenColumn ? 2 : 1}
        style={{
          textAlign: ["name", "companyName", "jobTitle"].includes(cell.column.id) ? "left" : "center",
          width: isContactHiddenColumn ? "40%" : cell.column.columnDef.meta?.size ?? "auto",
          maxWidth: isContactHiddenColumn ? "40%" : cell.column.columnDef.meta?.size ?? "auto",
        }}
        className={`p-4`}
        title={ typeof value === "string" && value.length > 12 ? value : ""}
      >
        {cell.column.id === "name"
          ? <div className="flex items-center">
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                handleCheckboxChange(cell.row.original.leadId)
              }}
              checked={isSelected}
              sx={{
                color: "var(--color-gray-600)",
                '&.Mui-checked': { color: "var(--color-primary-500)" },
                "&:hover": { backgroundColor: "transparent" },
              }}
            />
            {displayData(cell)}
          </div>
          : displayData(cell)}
      </td>
    })}
  </tr>
};
export default SearchMainRow;

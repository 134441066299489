import { LeadProfileDTO } from "utils/dtos/leadDTO";
import { EnrichmentStatus } from "utils/enums/enrichment";
export default class LeadHelper {
    public static isEnriched(status: EnrichmentStatus) {
        return status === EnrichmentStatus.SUCCESS;
    }
    public static isReadyForDownload(status: EnrichmentStatus) {
        return status === EnrichmentStatus.SUCCESS;
    }
    public static getConfidenceString(confidence: number) {
        if (confidence >= 25) return 'Verified';
        return "Suggested";
    }
    public static hasContactData(lead: LeadProfileDTO): boolean {
        if (lead.emailWork || lead.emailPersonal || lead.emailAlt || lead.phone || lead.phoneAlt) 
            return true;
        return false;
    }
}
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { AppSchemeENUM } from 'utils/enums/appENUM';

interface IAppContext {
  scheme: AppSchemeENUM;
  stripePublicKey: string | undefined;
}
interface StateContextType {
  app: IAppContext;
  // setApp: React.Dispatch<React.SetStateAction<IAppContext>>;
}
const StateContext = createContext<StateContextType>({
  app: {
    scheme: AppSchemeENUM.IDENTITY_MATRIX,
    stripePublicKey: undefined
  },
});
export const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [app, setApp] = useState<IAppContext>({
    scheme: AppSchemeENUM.IDENTITY_MATRIX,
    stripePublicKey: undefined
  });
  useEffect(() => {
    const bodyTheme = document.body.getAttribute('data-theme') as AppSchemeENUM;
    if (bodyTheme === AppSchemeENUM.QUALIGENCE) {
      setApp({
        scheme: bodyTheme,
        stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY_QUALIGENCE
      });
    } else {
      setApp({
        ...app,
        stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY
      });
    }
  }, []);
  return <StateContext.Provider value={{ app }}>{children}</StateContext.Provider>
}
export const useAppContext = (): StateContextType => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useStateContext must be used within a StateProvider');
  }
  return context;
};
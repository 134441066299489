import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ClientDTO } from "utils/dtos/clientDTO";
import { convertQueryString } from "utils/workWithData";

const baseUrl = process.env.REACT_APP_BASE_URL;
export interface ClientAnalyticsNodesDTO {
  id: number; step: number; name: string; visits: number;
}
interface ClientAnalyticsLinksDTO {
  source: number; target: number; value: number;
}
export interface ClientAnalyticsRowDTO {
  id: number; nodes: ClientAnalyticsNodesDTO[]; links: ClientAnalyticsLinksDTO[];
}
interface ClientAnalyticsResponse {
  rows: ClientAnalyticsRowDTO[];
  page: number;
  steps: number,
  isLastPage: boolean;
}
interface ClientState {
  data: ClientDTO | null
  isLoading: boolean
  isSuccess: boolean
  errorMessage: string | unknown
  analytics: {
    data: ClientAnalyticsResponse | null
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
}}
const initialState: ClientState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  errorMessage: null,
  analytics: {
    data: null, isLoading: false, isSuccess: false, isError: false
  }
};
export const getClientDTO = createAsyncThunk("client/get", async (clientId: string, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}client?client_id=${clientId}`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const updateWebhookURL = createAsyncThunk("client/updateWebhookURL", async (updateWebhookDto: {clientId: string; clayWebhookUrl: string | null}, thunkAPI) => {
  try {
    const {clientId, clayWebhookUrl} = updateWebhookDto;
    const response  = await axios.patch(`${baseUrl}client/${clientId}`, {clayWebhookUrl}, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true
    })
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data)
  }
})
export const getClientAnalytics = createAsyncThunk("client/getAnalytics", async (queryParams: any, thunkAPI) => {
  try {
    const query = await convertQueryString({...queryParams});
    const response = await axios.get(`${baseUrl}client/analytics?${query}`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
}})
export const clientSlice = createSlice({
  name: "crm", initialState, 
  reducers: {
    resetClientAnalyticsData: (state) => { state.analytics.data = null; }
  },
  extraReducers: (builder) => {
    builder.addCase(getClientDTO.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = null;
      state.data = action.payload.data;
    });
    builder.addCase(getClientDTO.rejected, (state, action: any) => {
      state.data = null;
      state.isLoading = false;
      state.isSuccess = true;
      state.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getClientDTO.pending, (state) => { state.isLoading = true; });

    builder.addCase(updateWebhookURL.fulfilled, (state, action) => {if (state.data) { 
      state.data.clayWebhookUrl = action.payload?.data.clayWebhookUrl
      state.isLoading = false
    }})
    builder.addCase(updateWebhookURL.pending, (state) => {state.isLoading = true})
    builder.addCase(updateWebhookURL.rejected, (state) => {state.isLoading = false})

    builder.addCase(getClientAnalytics.fulfilled, (state, action) => {
      if (action.payload.data.page === 1) {
        state.analytics.data = action.payload.data;
      } else {
        if (!state.analytics.data || state.analytics.data?.rows?.length === 0) {
          state.analytics.data = action.payload.data;
        } else {
          const newData = action.payload.data as ClientAnalyticsResponse;
          let newRows = state.analytics.data.rows.map((row) => {
            const existingRows = newData.rows.filter((r) => r.id === row.id);
            if (existingRows.length > 0) {
              for (const existingRowItem of existingRows) {
                row.nodes = row.nodes.concat(existingRowItem.nodes);
                row.links = row.links.concat(existingRowItem.links);
            }}
            return row
          });
          // ⭐ remove duplicated rows
          newRows = newRows.filter((row, index, self) => self.findIndex((r) => r.id === row.id) === index);
          state.analytics.data = { ...newData, rows: newRows }
        }
      }
      state.analytics.isSuccess = true;
      state.analytics.isLoading = false;
      state.analytics.isError = false;
    });
    builder.addCase(getClientAnalytics.rejected, (state, action: any) => {
      state.analytics.data = null;
      state.analytics.isLoading = false;
      state.analytics.isSuccess = false;
      state.analytics.isError = true;
    });
    builder.addCase(getClientAnalytics.pending, (state) => { state.analytics.isLoading = true; });
  }
});
export const {
  resetClientAnalyticsData
} = clientSlice.actions;
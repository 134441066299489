import React, { useEffect } from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import Button from "components/atoms/Button/Button";
import { useOutsideClick } from "utils/hooks/useOutsideClick";
import Icon from "components/atoms/Icon/Icon";
import Tooltip from "components/atoms/Tooltip/Tooltip";

const Popup: React.FC<{
  text?: string;
  onClose: () => void;
  style?: React.CSSProperties;
  className?: string;
  bodyClass?: string;
  buttonText?: string;
  type?: "modal" | "message";
  children?: React.ReactNode;
  noButton?: boolean;
  width?: "base" | "sm";
  closeOnOutsideClick?: boolean;
}> = ({
  onClose,
  text,
  style,
  className,
  bodyClass,
  buttonText,
  type = "modal",
  children,
  noButton,
  width = "base",
  closeOnOutsideClick = true,
}) => {
  const [closeAnimation, setCloseAnimation] = React.useState<boolean>(false);
  const popupClass = classNames(className, styles.popup);
  const popupBodyClass = classNames(
    bodyClass,
    styles.popup_body,
    closeAnimation && styles.closeAnimation,
  );
  // Using hook we close modal when user click out of if
  const ref = useOutsideClick(() => {handleClose();});
  const handleClose = () => {
    setCloseAnimation(true)
    setTimeout(() => {
      onClose();
    }, 400);
  }
  , escEvent = (event: any) =>{
    if (event.key === "Escape") handleClose()
  }
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", escEvent, false);
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", escEvent, false);
    };
  }, []);
  return type === "message" ? (
    <div className={popupClass} style={style}>
      <div className={popupBodyClass} ref={ref}>
        <p>{text}</p>
        {!noButton && (
          <Button onClick={handleClose}>{buttonText ?? "OK !"}</Button>
        )}
      </div>
    </div>
  ) : (
    <div className={popupClass} style={style}>
      <div className={
        `${popupBodyClass} w-full ${width === "sm" ? `sm:w-3/12` : `sm:w-5/12`}`
      } ref={closeOnOutsideClick ? ref : undefined}>
        
        <div className={styles.popup_body_close}>
          <Tooltip content="Press Esc to close">
            <div onClick={handleClose} className={styles.popup_body_close_btn}>
              <Icon name="close" size={17} />
            </div>
          </Tooltip>
        </div>

        {children}
        {/* {!noButton && <Button onClick={handleClose}>{buttonText}</Button>} */}
      </div>
    </div>
  );
};

export default Popup;

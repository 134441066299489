export default class PixelHelper {
    public static generateScript = (companyPixel: any) => {
        const bodyTheme = document.body.getAttribute("data-theme") as
            | "default"
            | "identity_matrix"
            | "qualigence";
        const company_identifier = (bodyTheme==="default")?"Identity Matrix":"Talent Matrix";
        if (companyPixel.data?.trackingCode) {
return `<!-- ${company_identifier} - Website Tracking -->
<script async src="${process.env.REACT_APP_CLIENT_URL}/trackingScript.js?pixelid=${companyPixel.data.trackingCode}"></script>`;
        }
        return "";
    }
}
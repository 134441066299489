import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { CrmParagonAuthDTO } from "utils/dtos/crmDTO";

const baseUrl = process.env.REACT_APP_BASE_URL;
interface CrmState {
  paragonAuth: {
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage: string;
    data: CrmParagonAuthDTO
}}
const initialState: CrmState = {
  paragonAuth: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: { projectId: null, authToken: null, isUserAuthorized: false, isClientAuthorized: false },
  },
};
export const getParagonAuthToken = createAsyncThunk("crm/paragon/clientAuth", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}crm/paragon/client_authorization`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const crmSlice = createSlice({
  name: "crm", initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getParagonAuthToken.fulfilled, (state, action) => {
      state.paragonAuth.isLoading = false;
      state.paragonAuth.isSuccess = true;
      state.paragonAuth.errorMessage = "";
      state.paragonAuth.data = action.payload.data;
    });
    builder.addCase(getParagonAuthToken.rejected, (state, action: any) => {
      state.paragonAuth.data = { projectId: null, authToken: null, isUserAuthorized: false, isClientAuthorized: false };
      state.paragonAuth.isLoading = false;
      state.paragonAuth.isSuccess = true;
      state.paragonAuth.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getParagonAuthToken.pending, (state) => { state.paragonAuth.isLoading = true; });
  },
});
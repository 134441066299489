import React from "react";
import {default as MenuUI} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import theme from "../../../assets/themes"
import Icon from "../Icon/Icon";
const Menu: React.FC<{
    anchor: null | HTMLElement;
    setAnchor: any;
    items?: {
        title: string;
        onClick: Function,
        isSelected?: boolean,
    }[],
    isOpen: boolean,
    children?: React.ReactNode
}> = ({ items = [], isOpen, anchor, setAnchor, children }) => {
    return <MenuUI
        anchorEl={anchor}
        open={isOpen}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        onClose={()=> setAnchor(null)}
        slotProps={{
            paper: {
                style: {
                    background: theme["--dropDown-bg"],
                    color: theme["--text-color-W"],
                    border: `1px solid ${theme["--border"]}`,
                    padding: 0,
                    marginTop: 10,
                    minWidth: 100,
                    borderRadius: 10,
                    WebkitBoxShadow: "4px 6px 5px 0px rgba(0,0,0,0.2)",
                    boxShadow: "4px 6px 5px 0px rgba(0,0,0,0.2)",
                },
            },
        }}
        MenuListProps={{
            disablePadding: true,
            variant: "menu",
        }}
    >
        {children 
        || 
        items.length > 0 && items.map((i, index) => {
            const isLast = items.length === (index +1)
            return <MenuItem 
                key={'menu-item'+index} 
                onClick={()=>{
                    if (i.isSelected) return
                    i.onClick();
                    setAnchor(null)
                }}
                style={{
                    color: i.isSelected ? 'white': `var(--color-white-500)`,
                    background: i.isSelected ? `var(--color-primary-500)` : 'transparent',
                    borderBottom: !isLast ? `1px solid ${theme["--border"]}` : 'none',
                    padding: "10px 16px",
                    gap: 5,
                    fontSize: 14,
                    fontFamily: '"Inter", sans-serif',
                    textShadow: `3px 2px 3px ${theme["--color-gradient-black-500"]}`
                }}
                selected={i.isSelected}
            >
                {i.title}
                {i.isSelected && <Icon name="check" />}
            </MenuItem>
        })}
    </MenuUI>
};

export default Menu;

import React, { useEffect } from "react";
import Button from "components/atoms/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { verifyAccount } from "store/slices/email.slice";
import Loading from "components/atoms/Loading/Loading";
import { Icon } from "components";
import theme from "assets/themes";
import style from "../User/Auth/style.module.scss";
const EmailVerify: React.FC = () => {
  const { isLoading } = useAppSelector((state) => state.email);
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    window.analytics.page("Email Verification")
    dispatch(verifyAccount(token || ""));
  }, []);
  useEffect(() => {
    const container = document.getElementById("bg-container");
    if (!container) return;
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, [isLoading]);
  if (isLoading) return <Loading />;
  return <div className={`${style.signup_container} h-screen`} id="bg-container">
    <div className={style.logo}>
      <img className={style.img} src={theme["logo"]} alt="logo" onClick={() => navigate("/welcome")} />
    </div>
    <div className={`${style.container_wrapper} bg-paper-500 gap-5 sm:gap-7`}>
      <div className="flex justify-center items-center bg-primary-500 w-32 h-32 rounded-full text-white-500">
        <Icon name="email" size={60} />
      </div>
      <div className="flex flex-col gap-3 text-center">
        <h1 className="text-white-500 font-bold text-2xl">🚀 Email verified!</h1>
        <p className="text-white-500">Your email has been successfully verified. You can now enjoy all the features of our platform.</p>
      </div>
      <Button onClick={() => navigate("/")}>Back to Dashboard</Button>
    </div>
  </div>
};

export default EmailVerify;

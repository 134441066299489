import React from "react";

const Container: React.FC<{
    children: React.ReactNode;
}> = ({
    children,
}) => {
    return (
        <div className={`container flex flex-col gap-4 sm:gap-5`}>
            {children}
        </div>
    )
};
export default Container
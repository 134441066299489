import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./slices/auth.slice";
import { listsSlice } from "./slices/list.slice";
import { userSlice } from "./slices/user.slice";
import { transactionSlice } from "./slices/transactions.slice";
import { cardSlice } from "./slices/card.slice";
import { dataSlice } from "./slices/data.slice";
import { emailSlice } from "./slices/email.slice";
import { companySlice } from "./slices/company.slice";
import { insightsSlice } from "./slices/insights";
import { subscriptionSlice } from "./slices/subscription.slice";
import { searchMainSlice } from "./slices/searchMain.slice";
import { couponSlice } from "./slices/coupon.slice";
import { uiSlice } from "./slices/ui.slice";
import { clientICPSlice } from "./slices/icp.slice";
import { sysSlice } from "./slices/sys.slice";
import { crmSlice } from "./slices/crm.slice";
import { clientSlice } from "./slices/client.slice";

const reducer = combineReducers({
  auth: authSlice.reducer,
  lists: listsSlice.reducer,
  user: userSlice.reducer,
  transactions: transactionSlice.reducer,
  cards: cardSlice.reducer,
  coupon: couponSlice.reducer,
  data: dataSlice.reducer,
  email: emailSlice.reducer,
  company: companySlice.reducer,
  insights: insightsSlice.reducer,
  searchMain: searchMainSlice.reducer,
  subscription: subscriptionSlice.reducer,
  icp: clientICPSlice.reducer,
  sys: sysSlice.reducer,
  ui: uiSlice.reducer,
  crm: crmSlice.reducer,
  client: clientSlice.reducer,
});
const store = configureStore({ reducer });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

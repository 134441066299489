import { IUser } from "types/user/user.type";
import constants from "../utils/constants";

export default class BusinessRulesHelper {
    public static hasEnoughCredits(user: IUser) {
        return BusinessRulesHelper.hasUnlimitedCredits(user.email) || user.creditBalance > 0;
    }
    public static hasUnlimitedCredits(email: string) {
        const splitedEmail = email.split('@')[1]
        const unlimitedCredits = splitedEmail === constants.REVENUE_INSTITUTE_DOMAIN;
        return unlimitedCredits;
    }
}
import React, { useEffect } from "react";
import Button from "components/atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import theme from "../../assets/themes"
import { AppSchemeENUM } from "utils/enums/appENUM";
import { useAppContext } from "utils/context/AppContext";


const Main: React.FC = () => {
  const navigate = useNavigate();
  const { app } = useAppContext();
  useEffect(() => {
    window.analytics.page("Welcome")
    const container = document.getElementById("bg-container");
    if (!container) return
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, [])
  
  return (
    <div className={`flex flex-col w-full h-screen justify-center items-center text-center gap-12`} id="bg-container">
      <div className="w-[250px]">
        <img
          className="w-full cursor-pointer object-contain"
          src={theme["logo"]} alt="logo"
          onClick={() => navigate("/welcome")}
        />
      </div>
      <div className={`flex flex-col p-7 border border-primary-500 rounded-xl gap-5`}>
        <h1 className="font-bold text-3xl leading-relaxed">Welcome to {theme["serviceName"]}</h1>
        <p className="text-base text-gray-300">To get you started please log in</p>
        <div className="flex justify-center gap-5">
          <Button onClick={() => app.scheme === AppSchemeENUM.IDENTITY_MATRIX ? navigate("/pricing") : navigate("/signup")} type="outline">Sign Up</Button>
          <Button onClick={() => navigate("/signin")}>Log In</Button>
        </div>
      </div>
    </div>
  );
}
export default Main;
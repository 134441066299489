import { useCallback, useEffect, useState } from "react";
import Container from "components/atoms/layout/Container/Container";
import "./../SearchMain/index.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setCurrentPage } from "store/slices/ui.slice";
import { parseJSON } from "../../../utils/parseJson";
import { useSearchParams } from "react-router-dom";
import { buildQueryString } from "../../../utils/buildQueryString";
import { getClientAnalytics, resetClientAnalyticsData } from "store/slices/client.slice";

import AnalyticsViewModule from "./AnalyticsViewModule";
import EmailVerafication from "components/molecules/EmailVerification/EmailVerification";

const CONTENT_CLASS = `bg-gray-900 rounded-lg flex flex-col h-[76vh]`;
const AnalyticsViewBlock = () => {
  const dispatch = useAppDispatch();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);
  const {isLoading: analyticsLoading, data: analyticsData } = useAppSelector(state => state.client.analytics);
  const [pageCurrent, setPageCurrent] = useState<number>(() => 1);
  // ⭐ Filters
  const [filterJobTitle, setFilterJobTitle] = useState<any[]>(() => parseJSON(params.job_title) || []);
  const [filterSeniority, setFilterSeniority] = useState<any[]>(() => parseJSON(params.seniority) || []);
  const [filterDepartment, setFilterDepartment] = useState<any[]>(() => parseJSON(params.department) || []);
  const [filterIndustry, setFilterIndustry] = useState<any[]>(() => parseJSON(params.industry) || []);
  const [filterCompanyHeadcount, setFilterCompanyHeadcount] = useState<any[]>(() => parseJSON(params.company_headcount) || []);
  const [filterLocationPerson, setFilterLocationPerson] = useState<any[]>(() => parseJSON(params.person_location) || []);

  useEffect(() => {
    if (params.person_location) setFilterLocationPerson(parseJSON(params.person_location));
    if (params.job_title) setFilterJobTitle(parseJSON(params.job_title));
    if (params.seniority) setFilterSeniority(parseJSON(params.seniority));
    if (params.department) setFilterDepartment(parseJSON(params.department));
    if (params.industry) setFilterIndustry(parseJSON(params.industry));
    if (params.company_headcount) setFilterCompanyHeadcount(parseJSON(params.company_headcount));
  }, [searchParams]);

  const personLocationFromUrl = searchParams.get("person_location")
  const jobTitleFromUrl = searchParams.get("job_title")
  const seniorityFromUrl = searchParams.get("seniority")
  const departmentFromUrl = searchParams.get("department")
  const industryFromUrl = searchParams.get("industry")
  const companyHeadcountFromUrl = searchParams.get("company_headcount")
  const pageFromUrl = searchParams.get('current_page');
  const queryParams = useCallback(() => { return {
    job_titles: buildQueryString(jobTitleFromUrl),
    company_headcounts: buildQueryString(companyHeadcountFromUrl),
    industries: buildQueryString(industryFromUrl),
    seniorities: buildQueryString(seniorityFromUrl),
    departments: buildQueryString(departmentFromUrl),
    locations_person: buildQueryString(personLocationFromUrl),
    page: pageFromUrl
  }},[ jobTitleFromUrl, industryFromUrl, seniorityFromUrl, departmentFromUrl, personLocationFromUrl, companyHeadcountFromUrl, pageFromUrl ])
  const fetchResults = useCallback(() => {
    dispatch(getClientAnalytics( queryParams() ));
  }, [queryParams]);
  useEffect(() => { fetchResults(); }, [fetchResults]);
  useEffect(() => { dispatch( resetClientAnalyticsData() ) },[ jobTitleFromUrl, industryFromUrl, seniorityFromUrl, departmentFromUrl, personLocationFromUrl, companyHeadcountFromUrl ])
  useEffect(() => { window.analytics.page("Analytics"); dispatch(setCurrentPage('analytics')) }, [])
  useEffect(() => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set('current_page', String(pageCurrent));
      return newParams;
    })
  }, [pageCurrent]);
  const { data: user } = useAppSelector((state) => state.user);


  return <Container>
    <div className=" sm:gap-6">
      
    <div className={`SearchMainBody ${CONTENT_CLASS} w-full sm:w-auto sm:basis-4/5 overflow-x-auto`}>
      {user.hasVerifiedEmail ?
          <>
          <AnalyticsViewModule 
              query={JSON.stringify(queryParams())}
              pageCurrent={pageCurrent}
              setPageCurrent={setPageCurrent}/>
           </>
            : <EmailVerafication/>}
      </div>
    </div>
  </Container>

};

export default AnalyticsViewBlock;

import React from "react";
import { default as TooltipUI } from "@mui/material/Tooltip";

const Tooltip: React.FC<{ content: string; children: any; isFullWidth?: boolean}> = ({ content, isFullWidth, children }) => {
  return (
    <TooltipUI
      title={content}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: { textAlign: "center", fontSize: 14, fontFamily: "Poppins, sans-serif", padding: "10px 9px",
            bgcolor: "rgba(0,0,0,0.9)", "& .MuiTooltip-arrow": { color: "rgba(0,0,0,0.9)", }, },
        },
      }}
    >
      <div style={{ position: "relative", width: isFullWidth ? "100%" : "fit-content", }}>
        {children}
      </div>
    </TooltipUI>
  );
};

export default Tooltip;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  InterfacePixelLeadData,
  getLeadStats,
  setLeadStats,
} from 'store/slices/insights';
import { secondsToHMS } from 'utils/workWithData';
import Skeleton from 'components/atoms/layout/Skeleton/Skeleton';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import constants from 'utils/constants';
import { getPersonalInfo } from 'store/slices/user.slice';
import Loading from 'components/atoms/Loading/Loading';

const PulseRow: React.FC<{
  row: Row<InterfacePixelLeadData>;
}> = ({ row }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isVisible, setIsVisible] = useState(false); // Track row visibility

  const observerRef = useRef<HTMLTableRowElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const {
    isLoading: CRIsLoading,
    isSuccess: CRIsSuccess,
    data: CRData,
  } = useAppSelector((state) =>
    state.insights.contactsRevealed.find((x) => x.id === row.original.id)
  ) ?? {};
  const { leadsStats } = useAppSelector((state) => state.insights);

  const stats = leadsStats.find((i) => i.leadId === row.original.id);

  const getRowStats = useMemo(() => async () => {
    if (stats || !isVisible) return; // Fetch only if visible and not already fetched
    dispatch(getLeadStats({ leadId: row.original.id }));
  }, [row.original.id, isVisible]);

  useEffect(() => {
    if (isVisible) getRowStats();
  }, [isVisible]);

  useEffect(() => {
    if (stats)
      dispatch(
        setLeadStats({
          id: row.original.id,
          duration: secondsToHMS(stats.duration),
          visits: stats.visits,
          pageViews: stats.pageViews,
          score: stats.leadScore,
        } as any)
      );
  }, [stats]);

  useEffect(() => {
    if (!CRIsSuccess || !CRData) return;
    setTimeout(() => {
      dispatch(getPersonalInfo());
    }, 10000);
  }, [CRIsSuccess]); // eslint-disable-line

  // Set up Intersection Observer
  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (observer.current) {
            observer.current.disconnect(); // Disconnect once observed
          }
        }
      });
    };
    if (observerRef.current && !observer.current) {
      observer.current = new IntersectionObserver(callback, {
        root: null,
        threshold: 0.1, // Trigger when 10% of the row is visible
      });
      observer.current.observe(observerRef.current);
    }
    return () => observer.current?.disconnect();
  }, []);

  return (
    <tr ref={observerRef}>
      {row.getVisibleCells().map((cell) => {
        const value = cell.getValue() as any;

        return (
          <td
            key={cell.id}
            className={`p-4 min-w-[150px]`}
            onClick={() => {
              searchParams.set('app_source', constants.PIXEL_KEYWORD);
              const currentParams = searchParams.toString();
              navigate(
                `/lead/${row.original.id}?${
                  currentParams ? `${currentParams}` : ''
                }`,
                { state: { from: location.pathname } }
              );
            }}
            style={{
              textAlign: cell.column.id === 'orgName' ? 'left' : 'center',
              width: cell.column.columnDef.meta?.size ?? 'auto',
            }}
            title={typeof value === 'string' && value.length > 12 ? value : ''}
          >
            {cell.column.id === 'isContactRevealed' ? (
              <>
                {CRIsLoading ? (
                  <Loading height="auto" spinnerSize={32} />
                ) : (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                )}
              </>
            ) : (
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
            {cell.column.id === 'visits' && (
              <>
                {stats?.visits !== undefined && stats?.visits !== null ? (
                  ''
                ) : (
                  <Skeleton width={40} height={20} />
                )}
              </>
            )}
            {cell.column.id === 'pageViews' && (
              <>
                {stats?.pageViews !== undefined && stats?.pageViews !== null ? (
                  ''
                ) : (
                  <Skeleton width={40} height={20} />
                )}
              </>
            )}
            {cell.column.id === 'duration' && (
              <>
                {stats?.duration !== null && stats?.duration !== undefined ? (
                  ''
                ) : (
                  <Skeleton width={40} height={20} />
                )}
              </>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default PulseRow;
